import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Form from 'react-bootstrap/Form';

import axiosInstance from '../../axios';

import '../tables.css'
import './sequence.css'

import Message from '../chat/message'
import DetailSequenceHeader from './detailSequenceHeader'

import { Table } from '@mui/material';

import SequenceListsCompany from './sequenceListsCompany.js'

import MainLoadingComponent from '../MainLoading';
import ResultAllMessages from './resultMessage';



const ResultSequence = (props) => {
	const { sequence } = props;
	
	console.log(sequence);
	
	const [appState, setAppState] = useState({
		loading: true,
		messages: null,
	});

	const [resultMessage, setResultMessage] = useState({
		messageInf: null
	})

	const PageData = (props) => {
		const { messages } = props;

		if (!messages || messages.length === 0) return <p>Error loading the page</p>;

		console.log(messages);

		const AverageValue = (props) => {
			const { averageAnswer } = props;

			if (averageAnswer) {
				if (averageAnswer < 0.5) {
					return (
						<div className="main-content-results-unit-details-satis" style={{color: 'red'}}>{ averageAnswer}%</div>
					)
				} else if (averageAnswer < 0.8) {
					return (
						<div className="main-content-results-unit-details-satis">{ averageAnswer}%</div>
					)
				} else {
					return (
						<div className="main-content-results-unit-details-satis" style={{color: 'green'}}>{ averageAnswer }%</div>
					)
				}
			} else {
				return (
					<div className="main-content-results-unit-details-satis" style={{color:' black'}}>NA</div>
				)
			}
		}

		return (
			<React.Fragment>
				<Grid container spacing={4}>
					<Grid item xs={12} style={{ overflowY: 'auto', maxHeight: '600px'}}>
						{messages.map((message) => (
								<div className="main-content-results-unit" key={message.id} style={{ marginRight: "1px" }} onClick={event => handleClickMessage(event, message.id)}>
									<div className="main-content-results-unit-category">
										{ message.messageOption.category }
									</div>
									<div className="main-content-results-unit-details">
										<div className="main-content-results-unit-details-text">
											{message.messageOption.contentEN }
										</div>
										<AverageValue averageAnswer={message.averageAnswer} />
									</div>
									<div style={{clear: 'both'}}></div>
								</div>
							)
						)}
					</Grid>
				</Grid>
			</React.Fragment>
		)
	}

	useEffect(() => {
		const path = "sequences/" + sequence.sequenceId + "/results";
		axiosInstance.get(path)
			.then((res) => {
				setAppState({ loading: false, messages: res.data});
				
				//console.log(res.data);

				axiosInstance.get("sequences/" + sequence.sequenceId + "/results/" + res.data[0].id)
					.then((res) => {
						setResultMessage({ messageInf: res ? res.data : null});
						
						// console.lsog(res.data);
					});
			});
	}, [setAppState]);

	const SeqActive = (props) => {
		if (sequence.used == false) {
			if (sequence.active == false) {
				return (<h3 style={{color: "red"}}>Not Started</h3>)
			} else {
				return (<h3 style={{color: "orange"}}>In Progress</h3>)
			}
		} else {
			return (<h3 style={{color: "green"}}>Finished</h3>)
		}
	}

	const handleClickMessage = (e, key) => {
		e.preventDefault();
		console.log(key);

		const path = "sequences/" + sequence.sequenceId + "/results/" + key;

		// console.log(path);
		axiosInstance.get(path)
			.then((res) => {
				setResultMessage({ messageInf: res.data});
				
				// console.lsog(res.data);
			});

	};

	const downloadReport = (props) => {
		var params = "";
		if (localStorage.getItem('username') && (localStorage.getItem('username') === "alexis" || localStorage.getItem('username') === "claire")) {
			params = "?template=Importance-Satisfaction"
		}

		const url = "sequences/" + sequence.sequenceId +"/results/download" +  params;
		console.log("getting: " + url);
		axiosInstance.get(url)
			.then((res) => {
				// setResultMessage({ messageInf: res.data});
				const data = res.data;
				if (data['generating_report'] == true) {
					alert("Generating the report, please try to download in a few seconds.");

				} else {
					fetch(data['url'], {
						method: 'GET',
						headers: {
						'Content-Type': 'application/zip',
						},
					})
					.then((response) => response.blob())
					.then((blob) => {
						// Create blob link to download
						const url = window.URL.createObjectURL(
							new Blob([blob]),
						);
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute(
							'download',
							'Sequence_Report.zip',
						);
	
						// Append to html link element page
						document.body.appendChild(link);
	
						// Start download
						link.click();
	
						// Clean up and remove the link
						link.parentNode.removeChild(link);
					});
				}
			
			});
	}

	if (!sequence || sequence.length === 0) return <p>Error loading the page</p>;
	return (
    <React.Fragment>
    	<DetailSequenceHeader sequence={sequence} />
		<Grid container spacing={4} style={{marginBottom: '20px'}}>
			<Grid item xs={6} style={{textAlign: "left"}}>
				<SeqActive />
			</Grid>
			<Grid item xs={6} style={{textAlign: "right"}}>
				<Button onClick={downloadReport}>
					Export Results
				</Button>
			</Grid>
		</Grid>
		
		<Grid container spacing={4}>
			<Grid item xs={3.5}>
				<PageData messages={appState.messages}/>
			</Grid>
			<Grid item xs={8.5}>
				<ResultAllMessages information={resultMessage.messageInf} />
			</Grid>
		</Grid>
		

		
	
		
    </React.Fragment>
  );
};




export default ResultSequence;