import React, { useEffect, useState } from 'react';
import './App.css';
import { useParams, useSearchParams } from "react-router-dom";
import DetailCompany from '../components/company/detailCompany';
import MainLoadingComponent from '../components/MainLoading';
import axiosInstance from '../axios';

import DetailCompanyLastMess from '../components/company/detailCompanyLastMess'


function Company() {
	let { companyId } = useParams();

	const [searchParams, setSearchParams] = useSearchParams();


	var MainLoading;
	
	
	if (searchParams.get("sub")) {
		MainLoading = MainLoadingComponent(DetailCompanyLastMess);
	} else {
		MainLoading = MainLoadingComponent(DetailCompany);
	}

	
	const [appState, setAppState] = useState({
		loading: true,
		company: null,
	});


	useEffect(() => {
		const path = "units/" + companyId;
		axiosInstance.get(path)
			.then((res) => {
				const company = res.data;
				setAppState({ loading: false, company: company });
				//console.log(res.data);
			});
	}, [setAppState]);
	return (
		<React.Fragment>
			<div className="main_app">
				<MainLoading isLoading={appState.loading} company={appState.company} />
			</div>
		</React.Fragment>
	);


}
export default Company;