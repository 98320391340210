import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
	baseURL: baseURL,
	timeout: 5000,
	headers: {
		Authorization: localStorage.getItem('access_token')
			? 'JWT ' + localStorage.getItem('access_token')
			: null,
		'Content-Type': 'application/json',
		accept: 'application/json',
	}, 
});

axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	async function (error) {
		const originalRequest = error.config;
		const next = window.location.pathname + window.location.search;

		if (typeof error.response === 'undefined') {
			// alert(
			// 	'A server/network error occurred. ' +
			// 		'Looks like CORS might be the problem. ' +
			// 		'Sorry about this - we will get it fixed shortly.'
			// );
			return Promise.reject(error);
		}

		console.log(error.response.data.code);
		console.log(error.response.status);
		console.log(error.response.statusText);
		console.log(originalRequest.url);

		if (
			// error.response.data.code === 'token_not_valid' &&
			error.response.status === 401 &&
			// error.response.statusText === 'Unauthorized' &&
			originalRequest.url !== '/token/refresh/'
		) {
			// console.log("JE SUIS LE FDP DE LA VIE");
			const refreshToken = localStorage.getItem('refresh_token');

			if (refreshToken) {
				const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));
				
				// exp date in token is expressed in seconds, while now() returns milliseconds:
				const now = Math.ceil(Date.now() / 1000);
				// console.log(tokenParts.exp);

				if (tokenParts.exp > now) {
					return axiosInstance
						.post('/token/refresh/', { refresh: refreshToken })
						.then((response) => {
							localStorage.setItem('access_token', response.data.access);
							localStorage.setItem('refresh_token', response.data.refresh);

							axiosInstance.defaults.headers['Authorization'] =
								'JWT ' + response.data.access;
							originalRequest.headers['Authorization'] =
								'JWT ' + response.data.access;

							return axiosInstance(originalRequest);
						})
						.catch((err) => {
							console.log(err);
						});
				} else {
					console.log('Refresh token is expired', tokenParts.exp, now);
					if (window.location.pathname !== '/login/') {
						window.location.href = '/login/?next=' + next;
					} 
					window.location.href = '/login/';
				}
			} else {
				console.log('Refresh token not available.');
				if (window.location.pathname !== '/login/') {
					window.location.href = '/login/?next=' + next;
				} 
				window.location.href = '/login/';
			}
		}

		if (error.response.status === 403) {
			window.location.href = "/";
			return
		}

		if (window.location.pathname !== '/login/') {
			window.location.href = '/login/?next=' + next;
		} 
		window.location.href = '/login/';

		// specific error handling done elsewhere
		return Promise.reject(error);
	}
);

export default axiosInstance;
