import React, { useEffect, useState } from 'react';
import '../pages/App.css';
import './tables.css'
import TableCompanies from './company/tableCompanies';
import MainLoadingComponent from './MainLoading';
import axiosInstance from '../axios';
import { useParams, useSearchParams } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

const CreateDiv = (props) => {
    const { toCreate, stateChange, companyId } = props;

    const initialFormData = Object.freeze({
      name: '',
    });

    const [formData, updateFormData] = useState({name: ''});

    const handleChange = (e) => {
      updateFormData({
        ...formData,
        // Trimming any whitespace
        [e.target.name]: e.target.value,
      });
    };

   

    const hide = () => {
      stateChange(false);
    };

    var name = "Company";
    
    if (toCreate === "company") {
      
    } else if (toCreate === "company_group") {
      name = "Company Group";
    } else {
      name = "Sequence";
    }

    const createElmt = (e) => {
      e.preventDefault();
      if (toCreate === "company") {
        console.log('company');
        
        axiosInstance.post('units/', {
          name: formData.name,
          companyId: companyId,
        }).then((res) => {
          // console.log('hahahahah')
          // console.log(res.data);
          window.location.href = "/units/" + res.data.companyId;
        })

      } else if (toCreate == "company_group") {
        axiosInstance.post('companies/', {
          name: formData.name,
        }).then((res) => {
          console.log(res);
          window.location.href = "/companies/" + res.data.companyId;
        });
      }else {
        axiosInstance.post('sequences/', {
          name: formData.name,
          companyId: companyId,
        }).then((res) => {
          console.log(res);
          window.location.href = "/sequences/" + res.data.sequenceId;
        });
      }
    };
  

    return (
      <React.Fragment>
        <div style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', backgroundColor:'rgb(220,220,220,0.6)', zIndex:' 100'}} />
        <div  style={{ backgroundColor: 'white', position: 'fixed', padding: "20px", width: '600px', left: '50%', marginLeft: '-300px', zIndex: '101', paddingBottom: '0', borderRadius: '8px', top: '50%', marginTop: '-120px'}}>
          <Button
            type="submit"
            variant="outline-danger"
            style={{ fontWeight: 'bold', position: 'absolute', top: '20px', right: '20px'}}
            onClick={hide}
          >
            Close
          </Button>
          <form noValidate>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <h3 style={{ marginBottom: "10px"}}>Create New {name}</h3>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="name"
                  label={name + " Name"}
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                    type="submit"
                    style={{ float: 'right' }}
                    className="submitButton"
                    variant="contained"
                    color="primary"
                    onClick={createElmt}
                >
                    Create {name}
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </React.Fragment>
      
    )

    }

export default CreateDiv;