import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { pink } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ButtonBS from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import moment from 'moment';

import axiosInstance from '../../axios';

import AddMessageOption from './addMessageOption'
import '../tables.css'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Message from '../chat/message'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';


import DetailSequenceHeader from './detailSequenceHeader'
import TableMessagesSequence from './tableMessagesSequence'


import MainLoadingComponent from '../MainLoading';
import { NavLink } from 'react-router-dom';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const StartSequence = (props) => {
	const { sequence } = props;
	const initialFormData = Object.freeze({
		nbDays: 1,
		newReferral: false,
    startDay: moment(),
    time: '10:30',
	});

	const [loadingMess, setloadingMess] = useState({
		loading: true,
		messages: null,
	});

  useEffect(() => {
		axiosInstance.get('sequences/' + sequence.sequenceId + '/messages')
			.then((res) => {
				const messages = res.data;
        console.log(messages);
				setloadingMess({ loading: false, messages: messages });
			});
	}, [setloadingMess]);

  const [loadingEmployees, setloadingEmployees] = useState({
		loading: true,
		employees: null,
	});

  useEffect(() => {
		axiosInstance.get('units/' + sequence.company.companyId + '/employees')
			.then((res) => {
				const employees = res.data;
        console.log(employees);
				setloadingEmployees({ loading: false, employees: employees });
			});
	}, [setloadingEmployees]);



	const [formData, updateFormData] = useState(initialFormData);


	useEffect(() => {
		axiosInstance.get('sequences/' + sequence.sequenceId).then((res) => {
			updateFormData({
				...formData,
				['name']: res.data.name,
				['speedyForm']: res.data.speedyForm,
			});
			console.log(res.data);
		});
	}, [updateFormData]);

	

  const TableActiveEmployees = (props) => {
    const { loading, employees } = props;

    if (loading) return <h3>Loading Employees</h3>

    if (!employees || employees.length === 0) return <h3 style={{ textAlign: 'center' }}>No Employees in the Company</h3>

    console.log(employees);

    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    return (
      <React.Fragment>
        <h3 style={{ textAlign: 'center' }}>No Employees in the Company</h3>
        <TableContainer component={Paper} style={{ marginRight: 'auto', marginLeft: 'auto', maxHeight: '800px', overflowY: 'auto', overflowX: 'clip'}}>
          <Table style={{ marginRight: 'auto', marginLeft: 'auto'}}>
            <TableHead>
              <TableRow>
                <StyledTableCell width="40%"  className="table_head_title">Platform</StyledTableCell>
                <StyledTableCell width="60%" align="center" className="table_head_title">Platform Id</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employees.map((employee) => 
                  {
                    if (employee.subscribed) {
                      return (
                          <StyledTableRow key={employee.employeeId}>
                            <StyledTableCell component="th" scope="row" className="table_body_title">
                              {employee.media}
                            </StyledTableCell>
                            <StyledTableCell align="center" className="table_body_title">{employee.platformId}</StyledTableCell>
                          </StyledTableRow>
                      )
                    }
                  })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </React.Fragment>
    
    )
  }


  const startSequence = () => {

    axiosInstance.patch('sequences/' + sequence.sequenceId, {
			recurrenceinDays: formData.nbDays,
      newReferral: formData.newReferral,
      date_started: formData.startDay.format("YYYY-MM-DD"),
      text_send_time: formData.time,
      active: true
		}).then((res) => {
      console.log(res.data);
      window.location.href = "/sequences/" + sequence.sequenceId;
    });
  }


	if (!sequence || sequence.length === 0) return <p>Error loading the page</p>;

  const orderingClosed = sequence.active + sequence.used;

  if (orderingClosed != 0) window.location.href = "/sequences/" + sequence.sequenceId;
  
  const LoadingMess = MainLoadingComponent(TableMessagesSequence);

  const handleChange = (e) => {
		updateFormData({
			...formData,
			// Trimming any whitespace
			[e.target.name]: e.target.value.trim(),
		});

	};

  const handle_change_referal_code = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    })
  }

  const changeNewData = (value) => {
    updateFormData({
			...formData,
			// Trimming any whitespace
			["startDay"]: moment(value),
		});
  }


	return (
    <React.Fragment>
      <DetailSequenceHeader sequence={sequence} />
      <Grid container spacing={4}>
        <Grid item xs={12} style={{ borderBottom: '1px solid black', paddingBottom: '10px' }}>
          <h3>Start <b>{sequence.name}</b> for <b>{sequence.company.name}</b></h3>
          <form novalidate>
            <Grid container spacing={0} style={{ marginTop: '30px' }}>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Grid container spacing="20px" justify="flex-end" alignItems="center" justifyContent="center">
                      <Grid item>
                          <DatePicker
                              renderInput={(props) => <TextField {...props} />}
                              label="Start date of Sequence"
                              name="startDay"
                              id="startDay"
                              onChange={(newValue) => changeNewData(newValue)}
                              value={formData.startDay}
                              inputFormat="dddd, DD/MM/yyyy"
                              format="DD/MM/YYYY"
                              disableMaskedInput={true}
                          />
                      </Grid>
                  </Grid>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={4}>
                  <Grid container spacing="20px" justify="flex-end" alignItems="center" justifyContent="center">
                      <Grid item>
                          <TextField
                              label="Time to Send"
                              name="time"
                              onChange={handleChange}
                              value={formData.time}
                          />
                      </Grid>
                      <Grid item>UTC</Grid>
                  </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing="20px" justify="flex-end" alignItems="center" justifyContent="center">
                  <Grid item xs={8}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="nbDays"
                      label="Days between messages"
                      name="nbDays"
                      onChange={handleChange}
                      value={formData.nbDays}
                      style={{ marginBottom: '20px' }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: pink[800],
                          '&.Mui-checked': {
                            color: pink[600],
                          },
                        }}
                        onChange={handle_change_referal_code}
                        checked={formData.newReferral}
                        id="newReferral"
                        name="newReferral"
                      />
                    }
                    label="Check if you want a new referral code"
                    labelPlacement="start"
                  />
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px'}}>
              <Button
                variant="outline-success"
                onClick={startSequence}

              >
                Start Sequence
              </Button>
            </Grid>
          </form>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: '20px', paddingBottom: '29px' }}>
          <Grid container spacing={4}>
            <Grid item xs={6} style={{ marginRight: "1px solid black" }}>
              <TableActiveEmployees loading={loadingEmployees.loading} employees={loadingEmployees.employees} />
            </Grid>
            <Grid item xs={6}>
              <h3 style={{ textAlign: 'center', marginBottom: '20px' }}>Messages in Sequence</h3>
              <LoadingMess isLoading={loadingMess.loading} messages={loadingMess.messages} orderingClosed={true} sequenceId={sequence.sequenceId} />
            </Grid>
          </Grid>
        </Grid>
        
      </Grid>    
    </React.Fragment>
  );
};




export default StartSequence;