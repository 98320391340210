import React, { useEffect, useState } from 'react';
import './App.css';
import TableSequences from '../components/sequence/tableSequences';
import MainLoadingComponent from '../components/MainLoading';
import axiosInstance from '../axios';


function Sequences() {
	const MainLoading = MainLoadingComponent(TableSequences);
	const [appState, setAppState] = useState({
		loading: true,
		posts: null,
	});

	useEffect(() => {
		axiosInstance.get("sequences/").then((res) => {
			const allSequences = res.data;
			setAppState({ loading: false, sequences: allSequences });
			console.log(res.data);
		});
	}, [setAppState]);
	return (
		<div className="main_app">
			<MainLoading isLoading={appState.loading} sequences={appState.sequences} />
		</div>
	);
}
export default Sequences;