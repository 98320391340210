import React, { useState, useEffect, useMemo } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { format } from "date-fns";
import { sortable } from 'react-sortable';
import MaterialReactTable from 'material-react-table';

import { Box, Typography } from '@mui/material';
import axiosInstance from '../../axios';
import { NavLink } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import '../tables.css'
import moment from 'moment';
import { Grid } from '@mui/material';
import Button from 'react-bootstrap/Button';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import CreateDiv from '../CreateDiv';
import axios from 'axios';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


class Item extends React.Component {
    render() {
        const date = new Date(this.props.children.date_to_send);
        var formattedDate = format(date, "MMMM do, yyyy");

        if (this.props.children.sent) {
            return (
                <li className='sortable-list-item' {... this.props}>
                    <HtmlTooltip
                        title={
                            <React.Fragment>
                                <Typography color="inherit">Message Sent:</Typography>
                                {formattedDate}
                            </React.Fragment>
                        } placement="right-start"
                    >
                        <Button style={{textAlign: 'left'}}>{this.props.children.messageOption.contentEN}</Button>
                    </HtmlTooltip>
                </li>
            )
        } else {
            return (
                <li className='sortable-list-item' {... this.props}>
                    <HtmlTooltip
                        title={
                            <React.Fragment>
                                <Typography color="inherit">Message to be Sent:</Typography>
                                {formattedDate}
                            </React.Fragment>
                        } placement="right-start"
                    >
                        <Button style={{textAlign: 'left'}}>{this.props.children.messageOption.contentEN}</Button>
                    </HtmlTooltip>
                </li>
            )
        }
        
    }
}

var SortableRow = sortable(Item);
  const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        },
    }));



class SortableTable extends React.Component {
    state = {
        items: this.props.items,
        orderingClosed: this.props.orderingClosed
    };
    
    onSortItems = (items) => {
        this.setState({
            items: items
        });
        
        console.log(items);
        console.log(items.map((obj) => obj.id).join(','))
    }
    
    render() {
        const { items, orderingClosed } = this.state;
        if (items.length != 0) {
            var listItems;
            if (!orderingClosed) {
                listItems = items.map(item => {
                    return (
                        <SortableRow
                            key={item.id}
                            onSortItems={this.onSortItems}
                            items={items}
                            sortId={item.order}
                        >
                            {item}
                        </SortableRow>
                    );
                });
            } else {
                listItems = items.map(item => {
                    return (
                        <Item
                            key={item.id}
                            items={items}
                        >
                            {item}
                        </Item>
                    );
                });
            }
            
        
            return (
                <ol className='sortable-list'>
                    {listItems}
                </ol>
            )
        } else {
            return (
                <TableRow colSpan={2} key="noMess">
                    <TableCell component="th" scope="row">
                        <p style={{fontWeight:"bold", textAlign:"center", fontSize:"14pt"}}>No messages in the sequence</p>
                    </TableCell>
                </TableRow>
            )
        }
        
    }
};




const TableMessagesSequence = (props) => {
    const { messages, orderingClosed, sequenceId } = props;
    var noMess;

    function updateOrdering(ordering) {
        axiosInstance.patch('sequences/' + sequenceId + "/messages", {
            ordering: ordering,
        }).then((res) => {
            console.log(res.data);
        });
    
        //window.location.reload();
    
    }
    

    const columns = useMemo(
        //column definitions...
        () => [
        {
            accessorKey: 'order',
            header: 'Order',
            size: 100,
        },
        {
            accessorKey: 'messageOption.contentEN',
            header: 'Content Message',
            size: 300,
        },
        ],
        [],
        //end
    );

    const DeleteButton = (props) => {
        const DeleteFct = (e) => {
            console.log(props.messageId)
            axiosInstance.post('sequences/' + sequenceId + '/messages', {
                delete: props.messageId
            }).then((res) => {
                window.location.reload();
            })
        };
        
        if (props.orderingEnable) {
            return (
                <Typography style={{ textAlign: "right"}}>
                    <Button aria-label="delete" size="large" onClick={DeleteFct}>
                        <DeleteIcon color="warning" />
                    </Button>
                </Typography>
            )
        }
        
    };

    const ReplyingSchemeEdit = (props) => {
        return (
            <Typography style={{ textAlign: "right", marginTop: '20px'}}>
                <NavLink to={"/sequences/" + sequenceId + "/?response_scheme=1"}>
                    <Button
                        variant="outline-success"
                    >
                        Response Scheme
                    </Button>
                </NavLink>
            </Typography>
        )
    };
    const [file, setFile] = useState()

    function selectFile(event) {
      setFile(event.target.files[0])
    }
  
    
    function submit_template(event) {
        event.preventDefault()

        const formData = new FormData();
        formData.append('sequence_template', file);
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };
        axiosInstance.post('sequences/' + sequenceId + '/upload_template', formData, config).then((response) => {
          alert("File uploaded, it may take a minute or two for the file to be processed.");
        });

    }
    

    const ShuffleQuestionOrder = (props) => {
        const shuffle = () => {
            axiosInstance.post('sequences/' + sequenceId + "/messages", {
                shuffle: true
            }).then((res) => {
                console.log(res.data);
                window.location.reload();
            });
        };
        return (
            <Typography style={{ textAlign: "left", marginTop: '20px'}}>
                <Button
                    variant="outline-success"
                    onClick={shuffle}
                >
                    Shuffle Order
                </Button>
            </Typography>
        )
    };

    const [data, setData] = useState(() => messages);

    var orderingEnable = true;

    if (orderingClosed) {
        orderingEnable = false;
    }

    const [showCreate, setShowCreate] = useState(false)
    
    const onClick = () => setShowCreate(true)

    // console.log("ordering" + orderingClosed);

    return (
        <React.Fragment>
            <MaterialReactTable
                autoResetPageIndex={false}
                columns={columns}
                data={data}
                paging={true}
                enableRowOrdering={orderingEnable}
                enableSorting={false}
                enableTopToolbar={false} //hide top toolbar
                enableBottomToolbar={true} //hide bottom toolbar
                muiTableBodyRowDragHandleProps={({ table }) => ({
                    onDragEnd: () => {
                        const { draggingRow, hoveredRow } = table.getState();
                        if (hoveredRow && draggingRow) {
                            data.splice(
                            hoveredRow.index,
                            0,
                            data.splice(draggingRow.index, 1)[0],
                            );
                            setData([...data]);
                        }

                        const newOrdering = data.map((obj) => obj.id).join(',');

                        updateOrdering(newOrdering);

                    },
                })}
            
                renderDetailPanel={({ row }) => {
                    var text = "To be Sent:";

                    if (row.original.sent) {
                        text = "Sent:"
                    }

                    var formattedDate = "";

                    if (row.original.date_to_send) {
                        const date = new Date(row.original.date_to_send);
                        formattedDate = format(date, "MMMM do, yyyy");
                    }
                    
                    return (
                        <Box
                            sx={{
                                display: 'grid',
                                margin: 'auto',
                                gridTemplateColumns: '1fr 1fr',
                                width: '90%',
                                textAlign: 'left',

                            }}
                        >
                            <Typography>
                                Category: <b>{row.original.messageOption.category}</b>
                                <br/>
                                <em style={{ color: "gray"}}>{text} <b>{formattedDate}</b></em>
                            </Typography>
                            <DeleteButton orderingEnable={orderingEnable} messageId={row.original.id} />
                        </Box>
                    )
                }
            }
            />
        
        {orderingEnable ? (
            <Grid container spacing={0}>
                <Grid item xs={6}>
                    <ShuffleQuestionOrder />
                    
                </Grid>
                <Grid item xs={6}>
                    <ReplyingSchemeEdit />
                </Grid>
                <Grid item xs={6} style={{ marginTop: '20px'}}>
                <form onSubmit={submit_template}>
                    <input type="file" onChange={selectFile}/>
                    <button type="submit">Upload file template</button>
                </form>
                </Grid>
            </Grid>
            
        ) : ''}
        </React.Fragment>
        
    );

};


export default TableMessagesSequence;