import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import Companies from './pages/Companies';
import CompanyGroup from './pages/CompanyGroup';
import Company from './pages/Company';
import Sequences from './pages/Sequences';
import Sequence from './pages/Sequence';
import CompanyGroups from './pages/CompanyGroups'

import reportWebVitals from './reportWebVitals';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "@fontsource/montserrat";

import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import Header from './components/Header';
import Login from './components/auth/login';
import Logout from './components/auth/logout';
import ChangePass from './pages/PasswordChange';
//import ResponsiveAppBar from './components/ResponsiveAppBar';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
		<React.StrictMode>
			<Header />
			<Routes>
					<Route path="/" element={<CompanyGroups />} />
					<Route path="/companies" element={<CompanyGroups />} />
					<Route path="/companies/:companyId" element={<CompanyGroup />} />
					<Route path="/units/:companyId" element={<Company />} />
					<Route path="/sequences" element={<Sequences />} />
					<Route path="/sequences/:sequenceId" element={<Sequence />} />
					<Route path="/login" element={<Login />} />
					<Route path="/change_pass" element={<ChangePass />} />
					<Route path="/logout" element={<Logout />} />
			</Routes>
		</React.StrictMode>
	</Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
