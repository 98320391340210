import React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';


import Button from 'react-bootstrap/Button';
import axiosInstance from '../../axios';

import '../tables.css'
import { NavLink } from 'react-router-dom';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));



const TableEmployees = (props) => {
  const [showExpired, setShowExpired] = React.useState(false)
  const show = () => {
    setShowExpired(true)
    document.body.style.overflow = "hidden"
  }
  const hide = () => {
    setShowExpired(false)
    document.body.style.overflow = "auto"
  }
	const { employees, companyId } = props;

  const removeEmployee = (e) => {
    console.log(e.target.value);
  
  
    axiosInstance.delete('employees/' + e.target.value)
      .then((res) => {
        console.log(res.data);
        window.location.reload();
      });
    
  };
  
  const addEmployee = (e) => {
    console.log(companyId);
    console.log(e.target.value);

    axiosInstance.post('employees', {
      companyId: companyId,
      employeeId: e.target.value,
    }).then((res) => {
      console.log(res.data);
      window.location.reload();
    });
  
  };

	if (!employees || employees.length === 0) {
    return (
      <React.Fragment>
        <div style={{ marginRight: 'auto', marginLeft: 'auto', width: '800px', marginTop: "20px"}}>
          <TableContainer component={Paper} style={{ marginRight: 'auto', marginLeft: 'auto', width: '800px', maxHeight: '500px', overflowY: 'auto', overflowX: 'clip'}}>
            <Table style={{ marginRight: 'auto', marginLeft: 'auto', width: '800px'}}>
                <TableBody>
                <StyledTableCell component="th" scope="row" className="table_body_title" style={{ textAlign: "center", fontWeight:"bold" }}>No Employees signed up for company</StyledTableCell>
                </TableBody>
                  
            </Table>
          </TableContainer>
        </div>
      </React.Fragment>
    );
  }

  var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
	return (
    <React.Fragment>
      <div style={{ marginRight: 'auto', marginLeft: 'auto', width: '800px', marginTop: "20px"}}>
        <TableContainer component={Paper} style={{ marginRight: 'auto', marginLeft: 'auto', width: '800px', maxHeight: '500px', overflowY: 'auto', overflowX: 'clip'}}>
          <Table style={{ marginRight: 'auto', marginLeft: 'auto', width: '800px'}}>
            <TableHead>
              <TableRow>
                <StyledTableCell width="40%"  className="table_head_title">Platform</StyledTableCell>
                <StyledTableCell width="40%" align="center" className="table_head_title">Platform Id</StyledTableCell>
                <StyledTableCell width="20%" align="right" className="table_head_title"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employees.map((employee) => 
                  {
                    if (employee.subscribed) {
                      return (
                        <StyledTableRow key={employee.employeeId}>
                          <StyledTableCell component="th" scope="row" className="table_body_title">
                            {employee.media}
                          </StyledTableCell>
                          <StyledTableCell align="center" className="table_body_title">{employee.platformId}</StyledTableCell>
                          <StyledTableCell align="center" className="table_body_title">
                            <Button
                              variant="danger"
                              value={ employee.employeeId }
                              onClick={removeEmployee}
                            >
                              Remove
                            </Button>
                            </StyledTableCell>
                        </StyledTableRow>
                      )
                    }
                  })
              }
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{textAlign:"right"}}>
          <Button
            type="submit"
            style={{ marginTop: '20px', marginBottom: '0' }}
            className="submitButton"
            variant="contained"
            color="primary"
            onClick={show}
          >
            See Unsubscribed Employees
          </Button>
        </div>
      </div>
      { showExpired ? (
        <React.Fragment>
          <div className='expired_background' onClick={hide}></div>
          <div id="expired" className="table_expired_employees">
            <div style={{ marginRight: 'auto', marginLeft: 'auto', width: '800px', marginTop: "20px"}}>
              <TableContainer component={Paper} style={{ marginRight: 'auto', marginLeft: 'auto', width: '800px', maxHeight: '500px', overflowY: 'auto', overflowX: 'clip'}}>
                <Table style={{ marginRight: 'auto', marginLeft: 'auto', width: '800px', fontSize: '12pt'}}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell width="40%"  className="table_head_title">Platform</StyledTableCell>
                      <StyledTableCell width="40%" align="center" className="table_head_title">Platform Id</StyledTableCell>
                      <StyledTableCell width="20%" align="center" className="table_head_title">
                      <Button
                        type="submit"
                        variant="outline-danger"
                        style={{ fontWeight: 'bold'}}
                        onClick={hide}
                      >
                        Close
                      </Button>
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {employees.map((employee) => 
                    {
                      if (!employee.subscribed) {
                        return(
                          <StyledTableRow key={employee.employeeId}>
                            <StyledTableCell component="th" scope="row" className="table_body_title">
                              {employee.media}
                            </StyledTableCell>
                            <StyledTableCell align="center" className="table_body_title">{employee.platformId}</StyledTableCell>
                            <StyledTableCell align="left" style={{ paddingLeft: "5%" }} className="table_body_title">
                              <Button
                                variant="outline-primary"
                                value={ employee.employeeId }
                                onClick={addEmployee}
                              >
                                Resubscribe
                              </Button>
                          </StyledTableCell>
                          </StyledTableRow>
                        )
                      }
                    })
                  }
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </React.Fragment>
      ) : null }
    </React.Fragment>
  );
};
export default TableEmployees;
