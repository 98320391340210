import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { pink } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ButtonBS from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import axiosInstance from '../../axios';

import AddMessageOption from './addMessageOption'
import '../tables.css'

import Message from '../chat/message'


import DetailSequenceHeader from './detailSequenceHeader'
import TableMessagesSequence from './tableMessagesSequence'


import MainLoadingComponent from '../MainLoading';
import { NavLink } from 'react-router-dom';



const DetailSequence = (props) => {
	const { sequence } = props;
	const initialFormData = Object.freeze({
		name: '',
		speedyForm: '',
	});

  const LoadingMess = MainLoadingComponent(TableMessagesSequence);
	const [loadingMess, setloadingMess] = useState({
		loading: true,
		messages: null,
	});

  useEffect(() => {
		axiosInstance.get('sequences/' + sequence.sequenceId + '/messages')
			.then((res) => {
				const messages = res.data;
				setloadingMess({ loading: false, messages: messages });
			});
	}, [setloadingMess]);



	const [formData, updateFormData] = useState(initialFormData);


	useEffect(() => {
		axiosInstance.get('sequences/' + sequence.sequenceId).then((res) => {
			updateFormData({
				...formData,
				['name']: res.data.name,
				['speedyForm']: res.data.speedyForm,
			});
			console.log(res.data);
		});
	}, [updateFormData]);

	const handleChange = (e) => {
		updateFormData({
			...formData,
			// Trimming any whitespace
			[e.target.name]: e.target.value,
		});
	};


	const updateDetails = (e) => {
		e.preventDefault();
		console.log(formData);

		axiosInstance.patch('sequences/' + sequence.sequenceId, {
			name: formData.name,
      speedyForm: formData.speedyForm,
		}).then((res) => {
      console.log(res.data);
    });

		window.location.reload();
	};

  const stopSequence = () => {
    axiosInstance.patch('sequences/' + sequence.sequenceId, {
      active: false,
      used: true
		}).then((res) => {
      console.log(res.data);
    });

    window.location.reload();
  }

  const deleteSequence = () => {
    axiosInstance.delete('sequences/' + sequence.sequenceId,)
      .then((res) => {
      console.log(res.data);
      window.location.href = "units/" + sequence.company.companyIds;
    });

    
  }


	if (!sequence || sequence.length === 0) return <p>Error loading the page</p>;

  const orderingClosed = sequence.active + sequence.used;
  


	return (
    <React.Fragment>
      <DetailSequenceHeader sequence={sequence} />
      <div>
        
        
					<Grid container spacing={4} style={{marginTop: '0px'}}>
            <Grid item xs={6} style={{borderRight: '1px solid lightgrey', paddingTop: '0', paddingBottom: '0', marginBottom: '20px'}}>
              <div style={{ marginRight: '20px'}}>
                <h2 style={{ marginBottom: "20px" }}>Overview Sequence</h2>
                <h4 style={{marginBottom: '40px'}}>
                  Unit: &nbsp;&nbsp;&nbsp;
                  <NavLink to={"/units/" + sequence.company.companyId } style={{ textDecoration: 'none'}}>
                    {sequence.company.name}
                  </NavLink>
                </h4>
                <form noValidate>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="name"
                        label="Sequence Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              color: pink[800],
                              '&.Mui-checked': {
                                color: pink[600],
                              },
                            }}
                            onChange={handleChange}
                            value={formData.speedyForm}
                            id="speedyForm"
                            name="speedyForm"
                          />
                        }
                        label="Speedy Form (i.e. new question sent if answered within 15 min)"
                        labelPlacement="start"
                      />
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    style={{ float: 'right', marginTop: "10px" }}
                    className="submitButton"
                    variant="contained"
                    color="primary"
                    onClick={updateDetails}
                  >
                    Save Sequence Settings
                  </Button>
                  
                </form>
              </div>
              <div style={{clear:'both'}}></div>
              <h4 style={{ marginTop: '20px'}}><b>Sequence started on</b>: {sequence.date_started}<br/><b>Time to send messages</b>: {sequence.text_send_time} UTC</h4>
              <div style={{ marginTop: "20px", marginRight: "20px"}}>
                <LoadingMess isLoading={loadingMess.loading} messages={loadingMess.messages} orderingClosed={orderingClosed} sequenceId={sequence.sequenceId} />
              </div>
            </Grid>
            <Grid item xs={6} style={{paddingTop: '0'}}>
              <AddMessageOption sequenceId={sequence.sequenceId} />
            </Grid>
          </Grid>
              

        <div style={{ clear: 'both' }}></div>
      </div>
        {sequence.active ? (
          <div style={{ borderTop: '1px solid black', marginBottom: '60px', marginTop: '20px'}}>
            <Button
              type="submit"
              style={{ float: 'right', marginTop: "10px" }}
              className="submitButton"
              variant="contained"
              color="primary"
              onClick={stopSequence}
            >
              End Sequence Early
            </Button>
          </div>
        ): ''}
        {orderingClosed ? '' : (
          <div style={{ borderTop: '1px solid black', marginBottom: '60px', marginTop: '20px'}}>
            <Button
              type="submit"
              style={{ float: 'right', marginTop: "10px" }}
              className="submitButton"
              variant="contained"
              color="primary"
              onClick={deleteSequence}
            >
              Delete Sequence
            </Button>
          </div>
        )}
      
      
        
    </React.Fragment>
  );
};




export default DetailSequence;