import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Form from 'react-bootstrap/Form';

import axiosInstance from '../../axios';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import '../tables.css'
import { format } from "date-fns";

import Message from '../chat/message'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));


const ResultAllMessages = (props) => {
    const { information } = props;

    // console.log(sequenceId, messageId);

    if (!information || information.length === 0) return "";

    return (
        <React.Fragment>
            {
                information.map((data) => {
                    return (
                        <React.Fragment>
                            <ResultMessage information={data} />
                            <hr/>
                        </React.Fragment>
                    )
                })
            }
        </React.Fragment>
    )
}

const ResultMessage = (props) => {
	const { information } = props;
    // console.log(information)

    // console.log(sequenceId, messageId);

    if (!information || information.length === 0) return "";

    console.log(information.rates);

    const DateSend = (props) => {
        const { info } = props;
        const date = new Date(info.dateToSend);
        var formattedDate = format(date, "MMMM do, yyyy");

        if (info.nbSentMessages != 0) {
            return (<h3>Sent { formattedDate }</h3>);
        } else if (formattedDate === "December 31st, 1969") {
            return ""
        } else {
            return(<h3 style={{ color: 'grey' }}>To send { formattedDate }</h3>);
        }

    };

    const DisplayPerc = (props) => {
        const { value } = props;

        if (value === "NA") {
            return(<h3>{value}</h3>)
        } else {
            if (value < 50) {
                return(<h3 style={{color: 'red'}}>{value}%</h3>)
            } else if (value < 80){
                return(<h3 style={{color: 'orange'}}>{value}%</h3>)
            } else {
                return(<h3 style={{color: 'green'}}>{value}%</h3>)
            }    
        }
    };

    const OtherTexts = (props) => {
        const { data } = props;

        if (!data || data.length === 0) return ""

        return (
            <React.Fragment>
                <h4 style={{ marginTop: '20px' }}>Other Answers:</h4>
                {
                    Object.entries(data)
                    .map( ([key, value]) => {
                        if (!['1', '2', '3', '4', '5', 'Other'].includes(key)) {
                            if (value === 1) {
                                return (
                                    <div>
                                        <p>- <b>{value} person replied: </b> "<i>{key}</i>"</p>
                                    </div>
                                )
                            }
                            return (
                                <div>
                                    <p>- <b>{value} people replied: </b> "<i>{key}</i>"</p>
                                </div>
                            )
                        }
                    })
                }
            </React.Fragment>
        )
    };

    const ResponseOption = (props) => {
        const { options } = props;
        console.log(props)
        if (options) {
            return (
                <ul>
                    {
                        options.map((opt) => {
                            return (<li>{opt.optionNumber}: {opt.valueEN}</li>)
                        })
                    }
                </ul>
            )
        } else {
            return (
                <ul>
                    <li>1: No</li>
                    <li>2: Not Often</li>
                    <li>3: Sometimes</li>
                    <li>4: Often</li>
                    <li>5: Yes</li>
                </ul>
            )
        }
    }

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={6}>
                    <h3 style={{textDecoration: 'underline', color: 'grey', marginBottom: '0', float: 'left' }}>{ information.message.category }</h3>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <DateSend info={information} />
                </Grid>
            </Grid>
            <h4 style={{ marginTop: '10px', marginBottom: "20px" }}>{ information.message.contentEN }</h4>
            <ResponseOption options={information.options} />
            <Grid container spacing={0} style={{ margin: '10px' }}>
                <Grid item xs={12}>
                    <Grid container spacing={0}>
                        <Grid item xs={3}>
                            <h4>{ information.nbSentMessages }</h4>
                        </Grid>
                        <Grid item xs={3}>
                            <DisplayPerc value={information.responseRate} />
                        </Grid>
                        <Grid item xs={3}>
                            <DisplayPerc value={ information.average } />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={0}>
                        <Grid item xs={3}>
                            sent messages
                        </Grid>
                        <Grid item xs={3}>
                            responding rate
                        </Grid>
                        <Grid item xs={3}>
                            average answer
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <h4 style={{ marginTop: '20px '}}>Answers:</h4>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        {
                            Object.entries(information.rates)
                            .map( ([key, value]) => {
                                if (['1', '2', '3', '4', '5', 'Other'].includes(key)) {
                                    return <StyledTableCell style={{ textAlign: 'center', fontSize: "12pt"}} className="table_head_title">{key}</StyledTableCell>
                                }
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    <StyledTableRow>
                        {
                            Object.entries(information.rates)
                            .map( ([key, value]) => {
                                if (['1', '2', '3', '4', '5', 'Other'].includes(key)) {
                                    return <StyledTableCell style={{ textAlign: 'center', fontSize: "12pt" }} className="table_cell_link" key={'rates_' + key}>{value}%</StyledTableCell>
                                } else {
                                    console.log(key);
                                }
                            })
                        }
                    </StyledTableRow>
                    <StyledTableRow>
                        {
                            Object.entries(information.rawRates)
                            .map( ([key, value]) => {
                                if (['1', '2', '3', '4', '5', 'Other'].includes(key)) {
                                    return <StyledTableCell style={{ textAlign: 'center', fontSize: "12pt"}} className="table_cell_link" key={'rawRates_' + key}>{value}</StyledTableCell>
                                }
                            })
                        }
                    </StyledTableRow>
                </TableBody>
                </Table>
            </TableContainer>
            <OtherTexts data={information.rawRates} />
            
        </React.Fragment>
    )
};




export default ResultAllMessages;