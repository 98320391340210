import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Form from 'react-bootstrap/Form';

import axiosInstance from '../../axios';

import '../tables.css'
import '../chat/chat.css'
import './sequence.css'

import Message from '../chat/message'
import DetailSequenceHeader from './detailSequenceHeader'

import { Table } from '@mui/material';

import SequenceListsCompany from './sequenceListsCompany.js'

import MainLoadingComponent from '../MainLoading';
import ResultMessage from './resultMessage';



const ImportMessage = (props) => {
	const { sequence } = props;
	
	console.log(sequence);
	
	const [appState, setAppState] = useState({
		loading: true,
		messages: null,
	});

	const [resultMessage, setResultMessage] = useState({
		messageInf: null
	})

	const CategoryOfMessage = (props) => {
		const { category, messages } = props;

		return (
			<React.Fragment>
				<h2>{category}</h2>
				<Grid container spacing={1} style={{ flexWrap: 'nowrap', marginBottom:'20px'}}>
					{messages.map((message) => {
						return (
							<Grid item xs={4}>
								<div className='message addMessageButton' key={message.messageId} style={{marginLeft: "10px", marginTop: "10px"}} onClick={() => handleClickMessage(message.messageId)}>
									{message.contentEN}
								</div>
							</Grid>
						)
					})}
					
				</Grid>
			</React.Fragment>
		)
	};

	const PageData = (props) => {
		const { messages } = props;

		if (!messages || messages.length === 0) return <p>Error loading the page</p>;

		console.log(messages);

		return (
			<React.Fragment>
				<Grid container spacing={4}>
					{
						Object.entries(messages)
						.map( ([key, value]) => {
							
							return (
								<Grid item xs={12} style={{ overflowX: 'auto' }}>
									<CategoryOfMessage category={key} messages={value} />
								</Grid>
							)
						})
					}
				</Grid>
			</React.Fragment>
		)
	}

	useEffect(() => {
		const path = "messages/" + sequence.sequenceId;
		axiosInstance.get(path)
			.then((res) => {
				setAppState({ loading: false, messages: res.data});
				
				console.log(res.data);
			});
	}, [setAppState]);

	const handleClickMessage = (messageId) => {

		const path = "messages/" + sequence.sequenceId;

		// console.log(path);
		axiosInstance.post(path, {
			messageId: messageId,
		  })
			.then((res) => {
				window.location.reload()
			});

	};

	if (!sequence || sequence.length === 0) return <p>Error loading the page</p>;
	return (
    <React.Fragment>
    	<DetailSequenceHeader sequence={sequence} />
		<PageData messages={appState.messages} />
    </React.Fragment>
  );
};




export default ImportMessage;