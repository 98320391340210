import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import axiosInstance from '../../axios';
import { NavLink } from 'react-router-dom';
import CreateDiv from '../CreateDiv';

import '../tables.css'
import { Grid } from '@mui/material';
import Button from 'react-bootstrap/Button';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const SequenceListsCompany = (props) => {
    const { sequences, companyId } = props;
    const [showCreate, setShowCreate] = useState(false)
    
    const onClick = () => setShowCreate(true)

    const useTemplate = (sequenceTemplate) => {
        axiosInstance.post('sequences/', {
            sequenceID_template: sequenceTemplate,
          }).then((res) => {
            console.log(res);
            // window.location.href = "/sequences/" + res.data.sequenceId;
          });
    }

    if (!sequences || sequences.length === 0) return <p>Can not find any posts, sorry</p>;

    

    return (
        <React.Fragment>
            { showCreate ? <CreateDiv toCreate={"sequence"} stateChange={setShowCreate} companyId={companyId} /> : null }
            <div style={{ borderRight: '1px solid lightgrey', paddingRight: "33px"}}>
                <h2 style={{ marginBottom: "20px", marginTop: "20px" }}>Sequences</h2>
                <div>
                    <h3>Past and current sequences</h3>
                    <p>Is the company enrolled in a sequence: YES</p>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 150 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                            <StyledTableCell width="100%" className="table_head_title">Used Sequences</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sequences.map((sequence) => {
                                    if (sequence.company.companyId == companyId) {
                                        return (
                                            <StyledTableRow key={sequence.sequenceId}>
                                                <StyledTableCell component="th" scope="row">
                                                    <NavLink to={"/sequences/" + sequence.sequenceId } className="table_cell_link">
                                                        {sequence.name}
                                                    </NavLink>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    }
                                })
                            }
                        </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div style={{marginTop: "40px"}}>
                    <Grid container spacing="4">
                        <Grid item xs={6}>
                            <h3>Enroll in Sequence</h3>
                        </Grid>
                        <Grid item align="right" xs={6}>
                            <Button
                                variant="outline-success"
                                onClick={onClick}
                            >
                                Create New
                            </Button>
                        </Grid>
                    </Grid>
                    
                    <TableContainer component={Paper} style={{ marginTop: "20px", marginBottom: "20px"}}>
                        <Table sx={{ minWidth: 150 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                            <StyledTableCell className="table_head_title" style={{minWidth: '100px !important'}}>Templates</StyledTableCell>
                            <StyledTableCell className="table_head_title" style={{minWidth: '100px'}}></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sequences.map((sequence) => {                    
                                    return (
                                        <StyledTableRow key={sequence.sequenceId}>
                                            <StyledTableCell component="th" scope="row">
                                                <NavLink to="/sequences/" className="table_cell_link">
                                                    {sequence.name}
                                                </NavLink>
                                            </StyledTableCell>
                                            <StyledTableCell component="th" align="right" scope="row" style={{paddingRight: "30px"}}>
                                                <Button
                                                    variant="outline-warning"
                                                    onClick={() => {axiosInstance.post('sequences/', {
                                                        sequenceID_template: sequence.sequenceId,
                                                        companyId: companyId
                                                      }).then((res) => {
                                                        console.log(res);
                                                        window.location.href = "/sequences/" + res.data.sequenceId;
                                                      });}}
                                                >
                                                    Use as Template
                                                </Button>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                            })}
                        </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>

        </React.Fragment>
    )
};


export default SequenceListsCompany;