import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import '../tables.css'
import { NavLink, useNavigate } from 'react-router-dom';
import CreateDiv from '../CreateDiv';
import CompanyPreviewUnit from './companyPreviewUnit'


const CompanyGroupList = (props) => {
	const { companies } = props;
  const [showCreate, setShowCreate] = useState(false)

  console.log(companies)
  const onClick = () => setShowCreate(true)

	if (!companies || companies.length === 0) {
    return (
      <React.Fragment>
          { showCreate ? <CreateDiv toCreate={"company_group"} stateChange={setShowCreate} /> : null }
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <h2 style={{marginBottom: "20px"}}>Companies</h2>
            </Grid>
            <Grid item xs={6}  style={{ textAlign: 'right'}}>
              <Button style={{ color: 'green'}} onClick={onClick}>Create</Button>
            </Grid>
          </Grid>
            
      </React.Fragment>
    );
  }



	return (
    <React.Fragment>
        { showCreate ? <CreateDiv toCreate={"company_group"} stateChange={setShowCreate}/> : null }
          <Grid container spacing={0}>
              <Grid item xs={6}>
                <h2 style={{marginBottom: "20px"}}>Companies</h2>
              </Grid>
              <Grid item xs={6}  style={{ textAlign: 'right'}}>
                <Button style={{ color: 'green'}} onClick={onClick}>Create</Button>
              </Grid>
            </Grid>
        <Grid container spacing={6}>
          {companies.map((company) => {
              if (!company.is_archived) {
                return (
                  <CompanyPreviewUnit company={company} key={company.companyId}/>
              )}
            }
          )}
        </Grid>
    </React.Fragment>
  );
};
export default CompanyGroupList;
