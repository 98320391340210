import React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import '../tables.css'
import { NavLink } from 'react-router-dom';

import DetailCompanyHeader from './detailCompanyHeader'


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const DetailCompanyLastMess = (props) => {
	const { company } = props;
	if (!company || company.length === 0) return <p>Error loading the page</p>;
	return (
    <React.Fragment>
      <DetailCompanyHeader company={company} />
      <h2>Last Sent Messages</h2>
    </React.Fragment>
  );
};
export default DetailCompanyLastMess;
