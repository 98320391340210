import React, { useEffect, useState } from 'react';
import './company.css'
import Grid from '@mui/material/Grid';
import { NavLink, useNavigate } from 'react-router-dom';


const CompanyPreviewUnit = (props) => {
	const { company } = props;

    const tagItem = (tag) => {
        return (
            <div >

            </div>
        )
    }

    const Logo = () => {
        if (company.linkToLogo) {
            return(
                <img src={company.linkToLogo} style={{width: '100%', height: "200px", objectFit: "cover", borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}}/>
            )
        } else {
            return(
                <img src="https://media3.giphy.com/media/xCwYFe19SldXLrJlwm/giphy.gif" style={{width: '100%', height: "200px", objectFit: "cover", borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}}/>
            )
        }
    }

	return (
        <Grid item xs={4}>
             <NavLink to={"/companies/" + company.companyId }>
                <div className='preview_unit'>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <Logo />
                        </Grid>
                        <Grid item xs={12} style={{width: '100%', padding: "10px"}}>
                            <h3 className="linkToUnit">{company.name}</h3>
                        </Grid>
                    </Grid>
                    
                </div>
             </NavLink>
            
        </Grid>
    );
};
export default CompanyPreviewUnit;