import React, { useEffect, useState } from 'react';
import './App.css';
import { useParams, useSearchParams } from "react-router-dom";
import DetailSequence from '../components/sequence/detailSequence';
import MainLoadingComponent from '../components/MainLoading';
import axiosInstance from '../axios';
import ResultSequence from '../components/sequence/resultSequence';
import ImportMessage from '../components/sequence/importMessage';
import ReplyingScheme from '../components/sequence/replyingScheme';

import DetailSequenceWorkflow from '../components/sequence/detailSequenceWorkflow'
import StartSequence from '../components/sequence/startSequence'


function Sequence() {
	let { sequenceId } = useParams();

	const [searchParams, setSearchParams] = useSearchParams();


	var MainLoading;

	const [appState, setAppState] = useState({
		loading: true,
		sequence: null,
	});

	
	if (searchParams.get("workflow")) {
		MainLoading = MainLoadingComponent(DetailSequenceWorkflow);
	} else if (searchParams.get("results")) {
		MainLoading = MainLoadingComponent(ResultSequence);
	} else if (searchParams.get("start")) {
		MainLoading = MainLoadingComponent(StartSequence);
	} else if (searchParams.get("import_messages")) {
		MainLoading = MainLoadingComponent(ImportMessage);
	} else if (searchParams.get("response_scheme")) {
		MainLoading = MainLoadingComponent(ReplyingScheme);
	} else {
		MainLoading = MainLoadingComponent(DetailSequence);
	}


	useEffect(() => {
		const path = "sequences/" + sequenceId;
		axiosInstance.get(path)
			.then((res) => {
				const sequence = res.data
				
				setAppState({ loading: false, sequence: sequence});
				
				//console.log(res.data);
			});
	}, [setAppState]);


	
	return (
		<React.Fragment>
			<div className="main_app">
				<MainLoading isLoading={appState.loading} sequence={appState.sequence} />
			</div>
		</React.Fragment>
	);


}
export default Sequence;