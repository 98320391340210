import React, {  useEffect } from 'react';
import axiosInstance from '../../axios';

export default function Logout() {
	useEffect(() => {
		const response = axiosInstance.post('user/logout/blacklist/', {
			refresh_token: localStorage.getItem('refresh_token'),
		});
		localStorage.removeItem('access_token');
		localStorage.removeItem('refresh_token');
		localStorage.removeItem('username');
		axiosInstance.defaults.headers['Authorization'] = null;
		window.location.href = '/login';
	});
	return <div>Logout</div>;
}
