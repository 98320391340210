import React from "react";


import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import '../Header.css'

const DetailCompanyHeader = (props) => {
    const { company } = props;
    // console.log(company);
    return (
    <Navbar collapseOnSelect style={{ paddingRight: "20px" }} expand="lg" variant="light" className="sub_header_nav">
        <Navbar.Brand href={"/companies/" + company.group.companyId} className="header_nav_navlink brand sub_header_nav_navlink">{company.group.name}</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
            <Nav>
                <Nav.Link href={"/units/" + company.companyId} className="header_nav_navlink sub_header_nav_navlink">Stats/Info</Nav.Link>
                {/* <Nav.Link href={"/units/" + company.companyId  + "/?sub=last"} className="header_nav_navlink sub_header_nav_navlink">Last Messages</Nav.Link> */}
                <Nav.Link href={"/sequences/" + company.lastSeq} className="header_nav_navlink sub_header_nav_navlink">Current Sequence</Nav.Link>
            </Nav>
        </Navbar.Collapse>
    </Navbar>
    );
};
    
export default DetailCompanyHeader;