import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

function MainLoading(Component) {
	return function MainLoadingComponent({ isLoading, ...props }) {
		if (!isLoading) return <Component {...props} />;
		return (
			<div style={{ margin: 'auto', position: 'fixed', width: '100%', height: '30px', top: 'calc(50% - 15px)', left: '0', textAlign: 'center' }}>
				<Spinner animation="grow" variant="dark" />
			</div>
			
		);
	};
}
export default MainLoading;
