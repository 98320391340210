import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

import axiosInstance from '../../axios';
import { NavLink } from 'react-router-dom';

import '../tables.css'
import moment from 'moment';
import { Grid } from '@mui/material';
import Button from 'react-bootstrap/Button';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const OneOffMessage = (props) => {
    const { messages, companyId } = props;
    var noMess;

    // console.log(messages);

    if (!messages || messages.length === 0) {
        noMess = true;
    } else {
        noMess = false;
    } 

    const newMessForm = Object.freeze({
		contentEN: '',
        date_to_send: moment()
	});

    const [messForm, updatemessForm] = useState(newMessForm);

    const handleChange = (e) => {
        updatemessForm({
          ...messForm,
          // Trimming any whitespace
          ["contentEN"]: e.target.value,
        });
      };

    
    const scheduleMessage = (e) => {
        axiosInstance.post('units/' + companyId + "/one_offs", {
            contentEN: messForm.contentEN,
            date_to_send: messForm.date_to_send.format("YYYY-MM-DD HH:mm"),

            }).then((res) => {
                console.log(res.data);
        });

        window.location.reload();
    };

    var pastToday = false;


    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };

    return (
        <React.Fragment>
            <div style={{ }}>
                <h2 style={{ marginBottom: "20px", marginTop: "20px" }}>One Off Messages</h2>
                <div style={{ overflowY: 'scroll', maxHeight: '700px' }}>
                    <h3>Current Schedule for this Company:</h3>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 150 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell width="70%" className="table_head_title">Message</StyledTableCell>
                                <StyledTableCell width="30%" style={{textAlign: "right"}} className="table_head_title">Scheduled day and time</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {noMess ? (
                                <StyledTableRow key="noMess">
                                    <StyledTableCell colSpan="2" component="th" scope="row">
                                        <p style={{fontWeight:"bold", textAlign:"center", fontSize:"14pt"}}>No scheduled messages for this company</p>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ) : 
                                messages.map((message) => {
                                    const dateLimit = new Date(message.date_to_send);
                                    const now = moment()

                                    if (pastToday === false && now.isAfter(dateLimit)) {                                            
                                        pastToday = true;
                                        return (
                                            <React.Fragment>
                                                <StyledTableRow key="past">
                                                    <StyledTableCell colSpan="2" component="th" scope="row">
                                                        <p style={{fontWeight:"bold", textAlign:"center", fontSize:"14pt"}}>Past Messages</p>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                                <StyledTableRow key={ message.messageId }>
                                                    <StyledTableCell component="th" scope="row">
                                                        <p style={{whiteSpace: 'pre-wrap'}}>{message.messageOption}</p>
                                                    </StyledTableCell>
                                                    <StyledTableCell  style={{textAlign: "right", paddingLeft: "60px"}} component="th" scope="row">
                                                        <p>{new Date(message.date_to_send).toLocaleDateString("en-UK", options)} UTC</p>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            </React.Fragment>
                                        );
                                    }

                                    if (pastToday === true) {
                                        return (
                                            <StyledTableRow key={ message.messageId }>
                                                <StyledTableCell component="th" scope="row">
                                                    <p style={{whiteSpace: 'pre-wrap'}}>{message.messageOption}</p>
                                                </StyledTableCell>
                                                <StyledTableCell  style={{textAlign: "right", paddingLeft: "60px"}} component="th" scope="row">
                                                    <p>{new Date(message.date_to_send).toLocaleDateString("en-UK", options)} UTC</p>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    }

                                    return (
                                        <StyledTableRow key={ message.messageId }>
                                            <StyledTableCell component="th" scope="row">
                                                <p style={{whiteSpace: 'pre-wrap'}}>{message.messageOption}</p>
                                            </StyledTableCell>
                                            <StyledTableCell  style={{textAlign: "right", paddingLeft: "60px"}} component="th" scope="row">
                                                <p>{new Date(message.date_to_send).toLocaleDateString("en-UK", options)} UTC</p>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    );



                                    
                                   
                                })
                            }
                        </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div>
                    <h3 style={{ marginBottom: "20px", marginTop: "40px" }}>Schedule a new message</h3>
                    <form noValidate>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="contentEN"
                            label="Content of the message in English"
                            name="contentEN"
                            style={{ marginBottom: '40px' }}
                            onChange={handleChange}
                            value={messForm.contentEN}
                            multiline
                            minRows={8}
                            maxRows={12}
                        />
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <Grid container spacing="20px" justify="flex-end" alignItems="center" justifyContent="center">
                                <Grid item>
                                    <DateTimePicker
                                        renderInput={(props) => <TextField {...props} />}
                                        label="Date and Time to Send"
                                        value={messForm.date_to_send}
                                        onChange={(newValue) => {updatemessForm({
                                            ...messForm,
                                            ["date_to_send"]: moment(newValue)
                                        })}}
                                    />
                                </Grid>
                                <Grid item>UTC</Grid>
                            </Grid>
                        </LocalizationProvider>
                        <Button
                            type="submit"
                            style={{ marginTop: "30px", float: 'right' }}
                            className="submitButton"
                            variant="contained"
                            color="primary"
                            onClick={scheduleMessage}
                        >
                            Schedule new message
                        </Button>
                        <div style={{clear: 'both'}}></div>

                    </form>
                </div>
            </div>
            

        </React.Fragment>
    )
};


export default OneOffMessage;