import React from 'react'

import './chat.css'

const Message = (props) => {
    const { messageId, content, style } = props;

    var text;

    if (content === 0) {
        
        text = props.children.join("");
    } else {
        text = content;
    }

    if (text === "") {
        return
    }

    return (
        <React.Fragment>
            <div className='message' key={messageId} style={style}>
                {text}
            </div>
        </React.Fragment>
    )

};

export default Message