import React from "react";


import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import '../Header.css'

const DetailSequenceHeader = (props) => {
    const { sequence } = props;

    if (!sequence || sequence.length === 0) return ''

    console.log(sequence.used);
    var showResults = false;
    if (sequence.active || sequence.used) {
        showResults = true
    }

    const modifyable = (sequence.active || sequence.used) ? false : true

    return (
    <Navbar collapseOnSelect style={{ paddingRight: "20px" }} expand="lg" variant="light" className="sub_header_nav">
        <Navbar.Brand href="#home" className="header_nav_navlink brand sub_header_nav_navlink">{sequence.name}</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
            <Nav>
                <Nav.Link href={"/sequences/" + sequence.sequenceId} className="header_nav_navlink sub_header_nav_navlink">Overview</Nav.Link>
                <Nav.Link href={"/sequences/" + sequence.sequenceId  + "/?workflow=1"} className="header_nav_navlink sub_header_nav_navlink">Sequence Worflow</Nav.Link>
                { modifyable ?  <Nav.Link href={"/sequences/" + sequence.sequenceId  + "/?import_messages=1"} className="header_nav_navlink sub_header_nav_navlink">Add Messages</Nav.Link> : '' }
            </Nav>
            <Nav className="me-auto"></Nav>
            <Nav>
                { modifyable ?  <Nav.Link href={"/sequences/" + sequence.sequenceId  + "/?start=1"} className="header_nav_navlink sub_header_nav_navlink" style={{ color: 'red', fontWeight: "bold"}}>Start</Nav.Link> : <Nav.Link href={"/sequences/" + sequence.sequenceId  + "/?results=1"} className="header_nav_navlink sub_header_nav_navlink" style={{ color: 'orange', fontWeight: "bold"}}>Results</Nav.Link>}
            </Nav>
        </Navbar.Collapse>
    </Navbar>
    );
};
    
export default DetailSequenceHeader;