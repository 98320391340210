import React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import '../tables.css'
import { NavLink } from 'react-router-dom';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const TableSequences = (props) => {
	const { sequences } = props;
	if (!sequences || sequences.length === 0) return <p>Can not find any posts, sorry</p>;
	return (
    <React.Fragment>
      <h2>Active Sequences</h2>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell width="33%" className="table_head_title">Sequence Name</StyledTableCell>
              <StyledTableCell width="33%" className="table_head_title">Related Company</StyledTableCell>
              <StyledTableCell width="33%" className="table_head_title">Number of Messages in Sequence</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sequences.map((sequence) => sequence.active ? (
                <StyledTableRow key={sequence.sequenceId}>
                  <StyledTableCell component="th" scope="row">
                  <NavLink to={"/sequences/" + sequence.sequenceId } className="table_cell_link">
                    {sequence.name}
                  </NavLink>
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                  <NavLink to={"/units/" + sequence.company.companyId } className="table_cell_link">
                    {sequence.company.name}
                  </NavLink>
                  </StyledTableCell>
                  <StyledTableCell align="left">{sequence.num_employee}</StyledTableCell>
                </StyledTableRow>
              ) : '')}
          </TableBody>
        </Table>
      </TableContainer>
      <h2 style={{ marginTop: "30px" }}>Finished Sequences</h2>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell width="33%" className="table_head_title">Sequence Name</StyledTableCell>
              <StyledTableCell width="33%" className="table_head_title">Related Unit</StyledTableCell>
              <StyledTableCell width="33%" className="table_head_title">Number of Messages in Sequence</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {sequences.map((sequence) => sequence.active ? '' : (
                <StyledTableRow key={sequence.sequenceId}>
                  <StyledTableCell component="th" scope="row">
                  <NavLink to={"/sequences/" + sequence.sequenceId } className="table_cell_link">
                    {sequence.name}
                  </NavLink>
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                  <NavLink to={"/units/" + sequence.company.companyId } className="table_cell_link">
                    {sequence.company.name}
                  </NavLink>
                  </StyledTableCell>
                  <StyledTableCell align="left">{sequence.num_employee}</StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};
export default TableSequences;
