import React from "react";


import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './Header.css'

function Header() {
    if (window.location.pathname.startsWith('/log')) {
        return (
            <Navbar collapseOnSelect style={{ paddingRight: "20px" }} expand="lg" variant="light" className="header_nav">
                <Navbar.Brand href="/" className="header_nav_navlink brand">PsyFyi</Navbar.Brand>
            </Navbar>
        );
    }

    const Name = () => {
        if (!localStorage.getItem('username')) {
            return ""
        }

        return localStorage.getItem('username').charAt(0).toUpperCase() + localStorage.getItem('username').slice(1)
    }

    const StagingBanner = () => {
        if (window.location.hostname.split('.')[0].endsWith('stage')) {
            return (<div style={{ backgroundColor: "red", color: "white", textAlign: "center", paddingTop: "3px", paddingBottom: "3px"}}>STAGING</div>)
        }

        return ''

    }


    return (
    <React.Fragment>
        <StagingBanner />
     <Navbar collapseOnSelect style={{ paddingRight: "20px" }} expand="lg" variant="light" className="header_nav">
        <Navbar.Brand href="/" className="header_nav_navlink brand">PsyFyi</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
            </Nav>
            <Nav>
                {/* <Nav.Link href="#features" className="header_nav_navlink">Sent Messages</Nav.Link> */}
            
            <Nav.Link href="/sequences" className="header_nav_navlink">Sequences</Nav.Link>
            <Nav.Link href="/" className="header_nav_navlink">Companies</Nav.Link>
            <NavDropdown title="Account" className="header_nav_navlink" id="collasible-nav-dropdown" drop={"start"}>
                <NavDropdown.ItemText className="header_nav_dropdown_name">Hi <Name /></NavDropdown.ItemText>
                {/* <NavDropdown.Item href="#action/3.2">
                Change Phone Number
                </NavDropdown.Item> */}
                <NavDropdown.Item href="/change_pass">Change Password</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/logout">
                Log Out
                </NavDropdown.Item>
            </NavDropdown>
            </Nav>
        </Navbar.Collapse>
    </Navbar>
    </React.Fragment>
    
    );
}
    
export default Header;