import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { pink } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import axiosInstance from '../../axios';

import '../tables.css'
import './company.css'

import DetailCompanyHeader from './detailCompanyHeader'
import TableEmployees from './tableEmployees'
import { Table } from '@mui/material';

import SequenceListsCompany from '../sequence/sequenceListsCompany.js'

import MainLoadingComponent from '../MainLoading';
import OneOffMessage from './oneOffMessage';


const DetailCompany = (props) => {
	const { company } = props;
  // console.log(company);
	const initialFormData = Object.freeze({
		companyId: '',
		name: '',
		description: '',
    basic_information: {}
	});

  const initialAddEmployeeData = Object.freeze({
		companyId: '',
		platformId: '',
	});

  const LoadingSeq = MainLoadingComponent(SequenceListsCompany);
  const LoadingOneOff = MainLoadingComponent(OneOffMessage);
	const [loadingSeq, setLoadingSeq] = useState({
		loading: true,
		sequences: null,
	});

  const [loadingMess, setLoadingMess] = useState({
		loading: true,
    messages: null,
	});


	useEffect(() => {
		axiosInstance.get("sequences")
			.then((res) => {
				const sequences = res.data;
				setLoadingSeq({ loading: false, sequences: sequences });
			});
	}, [setLoadingSeq]);

  useEffect(() => {
		axiosInstance.get('units/' + company.companyId + '/one_offs')
			.then((res) => {
				const messages = res.data;
        console.log(messages);
				setLoadingMess({ loading: false, messages: messages });
			});
	}, [setLoadingMess]);

	const [formData, updateFormData] = useState(initialFormData);
  const [employeeformData, updateEmployeeFormData] = useState(initialAddEmployeeData);

	useEffect(() => {
		axiosInstance.get('units/' + company.companyId).then((res) => {
      var basic_info = {};

      Object.entries(res.data.required_info_available).map( ([key, raw_info]) =>  {
        // console.log(raw_info)

        var required = false

        console.log(key)
        console.log(raw_info)

        res.data.required_information.map((requirement) => {
          console.log(requirement['information'])
          if (requirement['information']['pk'] == raw_info['pk']) {
            required = true
          }
        })

        var info = {
          'name': raw_info['name'],
          'required': required
        }

        basic_info[raw_info['pk']] = info
      })

      // console.log(basic_info)

			updateFormData({
				...formData,
				['name']: res.data.name,
				['description']: res.data.description,
        ['basic_information']: basic_info
			});

			console.log(res.data);
		});
	}, [updateFormData]);

	const handleChange = (e) => {
		updateFormData({
			...formData,
			// Trimming any whitespace
			[e.target.name]: e.target.value,
		});
	};

  const handleChange2 = (e) => {
		updateEmployeeFormData({
			...formData,
			// Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
		});
	};

	const updateDetails = (e) => {
		e.preventDefault();
		console.log(formData);

    var required_info =[];

    Object.entries(formData.basic_information).map( ([key, raw_info]) =>  {
      if (raw_info['required'] === true) {
        required_info.push(key);
      }
    })

    console.log({name: formData.name,
      description: formData.description,
      required_information: required_info,})

		axiosInstance.patch('units/' + company.companyId, {
			name: formData.name,
      description: formData.description,
      required_information: required_info,
		}).then((res) => {
      console.log(res.data);
    });

		window.location.reload();
	};

  const addEmployee = (e) => {
		e.preventDefault();
		console.log(formData);

		axiosInstance.post('employees', {
			companyId: company.companyId,
      platformId: employeeformData.platformId,
		}).then((res) => {
      console.log(res.data);
    });

		window.location.reload();
	};

  const archive = (e) => {
    axiosInstance.patch('units/' + company.companyId, {
      is_archived: true,
		}).then((res) => {
      console.log(res.data);
    });

    window.location.reload();
  }

  const downloadQrCode = (e) => {
    axiosInstance.get('qr_code_gen/' + company.lastSequencereferralId)
      .then((response) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob(response),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `qrcode.jpg`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  const handle_change_requirement = (e) => {
    var basic_info = formData.basic_information;

    console.log(basic_info); 
    console.log(e.target.name)

    basic_info[e.target.name]['required'] = e.target.checked;


		updateFormData({
			...formData,
      ["basic_information"]: basic_info,
		});

    console.log(formData);
	};


  const RequirementInfo = (props) => {
    const { info, pk } = props;
    // console.log(info);
    
    return (
      <FormControlLabel key={pk}
          control={
            <Checkbox
              sx={{
                color: pink[800],
                '&.Mui-checked': {
                  color: pink[600],
                },
              }}
              onChange={handle_change_requirement}
              checked={info.required}
              id={"requirement_" + pk}
              name={pk}
            />
          }
          label={info.name}
          labelPlacement="end"
        />
    )
  }

	if (!company || company.length === 0) return <p>Error loading the page</p>;
	return (
    <React.Fragment>
      <DetailCompanyHeader company={company} />
      <div style={{ borderBottom: '1px solid black'}}>
        <h2 style={{ marginBottom: "20px" }}>Edit Unit Details</h2>
        <form noValidate>
					<Grid container spacing={4} justify="flex-end" alignItems="center" style={{ marginBottom: '40px' }}>
						<Grid item xs={4}>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="name"
                      label="Company Name"
                      name="name"
                      autoComplete="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <h4>Required information to ask at sign up</h4>
                    <Grid container spacing={2} style={{ padding: '20px' }}>
                      {
                        formData.basic_information ? Object.entries(formData.basic_information)
                        .map( ([key, info]) =>  {
                          return (<RequirementInfo info={info} pk={key} />)
                        }) :''
                      }
                      
                    </Grid>
                  </Grid>
              </Grid>
						</Grid>
						<Grid item xs={8}>
							<TextField
								variant="outlined"
								required
                fullWidth
								id="description"
								label="Company description | Various Information:"
								name="description"
								autoComplete="description"
								value={formData.description}
								onChange={handleChange}
								multiline
								rows={6}
							/>
						</Grid>
					</Grid>
					<Button
						type="submit"
						style={{ float: 'right' }}
            className="submitButton"
						variant="contained"
						color="primary"
						onClick={updateDetails}
					>
						Update Company Details
					</Button>
          <Button
              type="submit"
              style={{ float: 'left', marginTop: "30px" }}
              className="submitButton"
              variant="contained"
              color="primary"
              onClick={archive}
            >
              Archive Company
            </Button>
				</form>

        <div style={{ clear: 'both' }}></div>
      </div>
      <div style={{ borderBottom: '1px solid black'}}>
        <h2 style={{ marginBottom: "80px", marginTop: '20px' }}>Employees related to this company</h2>
        <Grid container spacing={4}>
          <Grid item xs={6} style={{ textAlign: 'center', borderRight: '1px solid lightgrey', paddingTop: '0', paddingBottom: '0'  }}>
            <h3 style={{textAlign: 'left', marginBottom: '20px', marginTop : '0px'}}>Add employee manually</h3>
            <form noValidate>
              <TextField
                variant="outlined"
                required
                style={{ width: '400px' }}
                id="platformId"
                label="WhatsApp (add 44)"
                name="platformId"
                onChange={handleChange2}
              /><br/>
              <Button
                type="submit"
                style={{ marginTop: '20px', marginBottom: '0' }}
                className="submitButton"
                variant="contained"
                color="primary"
                onClick={addEmployee}
              >
                Add Employee
              </Button>
            </form>
          </Grid>
          <Grid item xs={6} style={{ paddingTop: '0' }}>
            <h3 style={{ marginTop : '0px' }}>Self subscription</h3>
            <p>For self subscription, ask employees to send referral code to the whatsapp number <u>+44 7563 109642</u> or to the messenger account <a href="https://www.facebook.com/PsyFyi-101801082627680" target="_blank" rel="noopener noreferrer">PsyFyi</a>.</p>
            <p>URL Link for Office staff test account:</p>
            <p style={{ textAlign: 'center', fontWeight: 'bold' }}>https://join.psy-fyi.com/{ company.lastSequencereferralId }</p>
            <Button
              onClick={downloadQrCode}
              className="submitButton"
            >
              Download basic QR code
            </Button>
          </Grid>
        </Grid>
        <TableEmployees employees={company.employees} companyId={company.companyId} />
				<div style={{ height: '20px'}}></div>
      </div>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <LoadingSeq isLoading={loadingSeq.loading_seq} sequences={loadingSeq.sequences} companyId={company.companyId}/>
        </Grid>
        <Grid item xs={6}>
          <LoadingOneOff isLoading={loadingMess.loading_1mess} messages={loadingMess.messages} companyId={company.companyId}/>
        </Grid>
      </Grid>
      
        
    </React.Fragment>
  );
};




export default DetailCompany;