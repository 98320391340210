import React, { useEffect, useState } from 'react';
import './App.css';
import { useParams, useSearchParams } from "react-router-dom";
import DetailCompanyGroup from '../components/company/detailCompanyGroup';
import MainLoadingComponent from '../components/MainLoading';
import axiosInstance from '../axios';

import DetailCompanyLastMess from '../components/company/detailCompanyLastMess'


function CompanyGroup() {
	let { companyId } = useParams();

	const [searchParams, setSearchParams] = useSearchParams();


	const MainLoading = MainLoadingComponent(DetailCompanyGroup);

	
	const [appState, setAppState] = useState({
		loading: true,
		company: null,
	});


	useEffect(() => {
		const path = "companies/" + companyId;
		axiosInstance.get(path)
			.then((res) => {
				const company = res.data;
				setAppState({ loading: false, company: company });
				//console.log(res.data);
			});
	}, [setAppState]);
	return (
		<React.Fragment>
			<div className="main_app">
				<MainLoading isLoading={appState.loading} company={appState.company} />
			</div>
		</React.Fragment>
	);


}
export default CompanyGroup;