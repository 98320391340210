import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Form from 'react-bootstrap/Form';

import axiosInstance from '../../axios';

import '../tables.css'

import Message from '../chat/message'


const AddMessageOption = (props) => {
	const { sequenceId } = props;

    const newMessForm = Object.freeze({
		contentEN: '',
		category: '',
        exampleQuestion: 'Have you had supernatural experiences onboard ship?',
	});

    const [messForm, updatemessForm] = useState(newMessForm);

    const handleChangeMessage = (e) => {
        var exampleQuestion = 'Have you had supernatural experiences onboard ship?';

        if (e.target.name === 'contentEN' && e.target.value !== ""){
            exampleQuestion = "";
        }

        updatemessForm({
            ...messForm,
            exampleQuestion: exampleQuestion,
            [e.target.name]: e.target.value,
        });
            

    };

    const createNewMessage = (e) => {
        e.preventDefault();

        console.log(messForm);


        axiosInstance.post('sequences/' + sequenceId + "/messages", {
            contentEN: messForm.contentEN,
            category: messForm.category,
        }).then((res) => {
            console.log(res.data);
        });

        window.location.reload();
    };

	return (
    <React.Fragment>
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <h3>Example Message</h3>
                <Message messageId='fake' content={0}>
                Hello from PsyFyi, {"\n"}{messForm.exampleQuestion}{messForm.contentEN}
                {"\n"}{"\n"}Please respond to today's question with:
                {"\n"}1 - No
                {"\n"}2 - Not often
                {"\n"}3 - Sometimes
                {"\n"}4 - Often
                {"\n"}5 - Yes
                {"\n"}{"\n"}Send STOP to stop receiving our texts.
                {"\n"}{"\n"}- PsyFyi
                </Message>
            </Grid>
            <Grid item xs={12}>
                <h3>Write New Message</h3>
                <Form>
                <Form.Select aria-label="Default select example" name="category" onChange={handleChangeMessage}>
                    <option>Category</option>
                    <option value="Rest & Fatigue">Rest & Fatigue</option>
                    <option value="Time Pressure">Time Pressure</option>
                    <option value="Physical environment">Physical environment</option>
                    <option value="Mental wellbeing">Mental wellbeing</option>
                    <option value="Engagement and ownership">Engagement and ownership</option>
                    <option value="Time planning">Time planning</option>
                    <option value="Suggestions and concerns">Suggestions and concerns</option>
                    <option value="Social Life">Social Life</option>
                    <option value="Communication">Communication</option>
                    <option value="Crew Welfare">Crew Welfare</option>
                    <option value="Engagement">Engagement</option>
                    <option value="Recognition">Recognition</option>
                    <option value="Teamwork and collaboration">Teamwork and collaboration</option>
                    <option value="Tools & Equipment">Tools & Equipment</option>
                    <option value="Training & Skills">Training & Skills</option>
                    <option value="Difficult to succeed professionally">Difficult to succeed professionally</option>
                    <option value="Challenging social relations">Challenging social relations</option>
                    <option value="Employment challenges">Employment challenges</option>
                    <option value="Physical conditions onboard">Physical conditions onboard</option>
                    <option value="Other">Other</option>
                </Form.Select>
                <Form.Group controlId="exampleForm.ControlTextarea1" style={{ marginTop: "20px"}}>
                    <Form.Label>Content of Message:</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows="3"
                        onChange={handleChangeMessage}
                        name="contentEN"
                    />
                </Form.Group>
                <Button type="submit" style={{ marginTop: "10px" }} onClick={createNewMessage}>Create New Message</Button>
                </Form>
            </Grid>
        </Grid>
    </React.Fragment>
    );
};




export default AddMessageOption;