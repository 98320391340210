import React, { useState } from 'react';
import axiosInstance from '../../axios';

import { useSearchParams } from 'react-router-dom';

//MaterialUI
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import './login.css'


export default function SignIn() {
	const initialFormData = Object.freeze({
		username: '',
		password: '',
	});
	const [searchParams, setSearchParams] = useSearchParams();

	const [formData, updateFormData] = useState(initialFormData);

	const handleChange = (e) => {
		updateFormData({
			...formData,
			[e.target.name]: e.target.value.trim(),
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(formData);

		axiosInstance
			.post(`token/`, {
				username: formData.username,
				password: formData.password,
			})
			.then((res) => {
				localStorage.setItem('username', formData.username);
				localStorage.setItem('access_token', res.data.access);
				localStorage.setItem('refresh_token', res.data.refresh);
				axiosInstance.defaults.headers['Authorization'] =
					'JWT ' + localStorage.getItem('access_token');

				if (searchParams.get("next")) {
                	window.location.href = searchParams.get("next");
				} else {
					window.location.href = "/";
				}
				//console.log(res);
				//console.log(res.data);
			});
	};


	return (
		<Container component="main" className='main_app' maxWidth="xs">
			<CssBaseline />
			<div >
				<Typography component="h1" variant="h5">
					Sign in
				</Typography>
				<form noValidate>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="username"
						label="Username"
						name="username"
						autoComplete="username"
						autoFocus
						onChange={handleChange}
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="password"
						label="Password"
						type="password"
						id="password"
						autoComplete="current-password"
						onChange={handleChange}
					/>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						onClick={handleSubmit}
					>
						Sign In
					</Button>
				</form>
			</div>
		</Container>
	);
}
