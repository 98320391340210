import React, { useEffect, useState } from 'react';
import './App.css';
import CompanyGroupList from '../components/company/companyGroupList';
import MainLoadingComponent from '../components/MainLoading';
import axiosInstance from '../axios';

function CompanyGroups() {
	const MainLoading = MainLoadingComponent(CompanyGroupList);
	const [appState, setAppState] = useState({
		loading: true,
		posts: null,
	});


	useEffect(() => {
		axiosInstance.get("companies/")
			.then((res) => {
				const allCompanies = res.data;
				setAppState({ loading: false, companies: allCompanies });
				console.log(res.data);
			});
	}, [setAppState]);
	
	return (
		<div className="main_app">
			<MainLoading isLoading={appState.loading} companies={appState.companies} />
		</div>
	);
}
export default CompanyGroups;