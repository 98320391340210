import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Form from 'react-bootstrap/Form';

import axiosInstance from '../../axios';

import '../tables.css'

import Message from '../chat/message'
import DetailSequenceHeader from './detailSequenceHeader'

import { Table } from '@mui/material';

import SequenceListsCompany from './sequenceListsCompany.js'

import MainLoadingComponent from '../MainLoading';



const DetailSequenceWorkflow = (props) => {
	const { sequence } = props;
	
	console.log(sequence);

	const [appState, setAppState] = useState({
		loading: true,
		workflow: null,
	});

	const handleSubmit = function(messageId) {
		axiosInstance.patch('sequences/' + sequence.sequenceId + '/workflow', {
			"update": messageId,
		}).then(() => {
			window.location.reload();
		});

		
	};

	const newMessForm = Object.freeze({
		system: '',
		contentEN: '',
	});
	const displayAdv = Object.freeze({
		display: 'none',
	});

    const [messForm, updatemessForm] = useState(newMessForm);
	const [displayAdvice, updatedisplayAdvice] = useState(displayAdv);

	const handleChangeMessage = (e) => {
		e.preventDefault();
		console.log(e.target.name);
		updatemessForm({
			...messForm,
			[e.target.name]: e.target.value,
		});

		if (e.target.name === 'system') {
			if (e.target.value === "base_text") {
				updatedisplayAdvice({ display: 'block' });
			} else {
				updatedisplayAdvice({ display: 'none' });
			}
		}

		
	};

	const createNewMessage = (e) => {
        e.preventDefault();


        axiosInstance.post('sequences/' + sequence.sequenceId + "/workflow", {
            contentEN: messForm.contentEN,
            system: messForm.system,
                }).then((res) => {
            console.log(res.data);
        });

        window.location.reload();
    };

	const PageData = (props) => {
		const { workflow } = props;

		if (!workflow || workflow.length === 0) return <p>Error loading the page</p>;

		console.log(workflow);

		return (
			<React.Fragment>
				<Grid container spacing={1} style={{ marginLeft: '20px', marginRight: '20px' }}>
					<h2 style={{ marginTop: '60px' }}>Question Template</h2>
					<Grid container spacing={4} style={{marginTop: '-20px'}}>
						<Grid item xs={6}>
							<h3 style={{ }}>Current Question Template:</h3>
							<div style={{backgroundColor: 'rgb(245, 245, 245)', padding: '10px', borderRadius: '4px'}}>
								<Message messageId='currBase' content={workflow.current.base.contentEN} style={{marginLeft: "10px", marginTop: "10px"}}></Message>
								<Message messageId='currBase-res' content="4" style={{marginRight: "10px", marginTop: "10px", backgroundColor:"rgb(83, 255, 75)", width:"50px"}}></Message>
							</div>
						</Grid>
						<Grid item xs={6}>
							<h3 style={{  }}>Select Another Base Template:</h3>
							<div style={{display: 'flex', flexFlow: 'row', overflowX: 'scroll', maxWidth: '100%', alignItems: 'flex-start'}}>
								{workflow.base_text ? workflow.base_text.map((message) => {
									return (
										<div style={{ marginRight: '20px', cursor: 'pointer'}} onClick={() => handleSubmit(message.messageId)} >
											<Message messageId={message.messageId} content={message.contentEN} style={{backgroundColor:"rgb(219, 118, 194)"}} onClick={handleSubmit}/>
										</div>
									)
								}) : ''}
							</div>
						</Grid>
					</Grid>
					<h2 style={{ marginTop: '60px' }}>Referral Text</h2>
					<Grid container spacing={4} style={{marginTop: '-20px'}}>
						<Grid item xs={6}>
							<h3 style={{ }}>Current Referral Text:</h3>
							<div style={{backgroundColor: 'rgb(245, 245, 245)', padding: '10px', borderRadius: '4px'}}>
								<Message messageId='currBase-res' content="4fl3dswq1" style={{marginRight: "10px", marginTop: "10px", backgroundColor:"rgb(83, 255, 75)", width:"150px"}}></Message>
								<Message messageId='currBase' content={workflow.current.referral.contentEN} style={{marginLeft: "10px", marginTop: "10px"}}></Message>
							</div>
						</Grid>
						<Grid item xs={6} >
							<h3 style={{  }}>Select Another Referral Message:</h3>
							<div style={{display: 'flex', flexFlow: 'row', overflowX: 'scroll', maxWidth: '100%', alignItems: 'flex-start'}}>
								{workflow.referral ? workflow.referral.map((message) => {
									return (
										<div style={{ marginRight: '20px', cursor: 'pointer'}} onClick={() => handleSubmit(message.messageId)} >
											<Message messageId={message.messageId} content={message.contentEN} style={{backgroundColor:"rgb(219, 118, 194)"}}/>
										</div>
									)
								}) : ''}
							</div>
							
						</Grid>
					</Grid>
					<h2 style={{ marginTop: '60px' }}>Right Answer</h2>
					<Grid container spacing={4} style={{marginTop: '-20px'}}>
						<Grid item xs={6}>
							<h3 style={{ }}>Current Right Answer:</h3>
							<div style={{backgroundColor: 'rgb(245, 245, 245)', padding: '10px', borderRadius: '4px'}}>
								<Message messageId='currBase' content="Have you ever experienced any supernatural activities onboard?" style={{marginLeft: "10px", marginTop: "10px"}}></Message>
								<Message messageId='currBase-res' content="4" style={{marginRight: "10px", marginTop: "10px", backgroundColor:"rgb(83, 255, 75)", width:"50px"}}></Message>
								<Message messageId='currBase' content={workflow.current.right.contentEN} style={{marginLeft: "10px", marginTop: "10px"}}></Message>
							</div>
						</Grid>
						<Grid item xs={6}>
							<h3 style={{  }}>Select Another Right Message:</h3>
							<div style={{display: 'flex', flexFlow: 'row', overflowX: 'scroll', maxWidth: '100%', alignItems: 'flex-start'}}>
								{workflow.right_answer ? workflow.right_answer.map((message) => {
									return (
										<div style={{ marginRight: '20px', cursor: 'pointer'}} onClick={() => handleSubmit(message.messageId)} >
											<Message messageId={message.messageId} content={message.contentEN} style={{backgroundColor:"rgb(219, 118, 194)"}}/>
										</div>
									)
								}) : ''}
							</div>
						</Grid>
					</Grid>
					<h2 style={{ marginTop: '60px' }}>Other Answer</h2>
					<Grid container spacing={4} style={{marginTop: '-20px'}}>
						<Grid item xs={6}>
							<h3 style={{ }}>Current Other Answer:</h3>
							<div style={{backgroundColor: 'rgb(245, 245, 245)', padding: '10px', borderRadius: '4px'}}>
								<Message messageId='currBase' content="Do you feel happy working for PsyFyi?" style={{marginLeft: "10px", marginTop: "10px"}}></Message>
								<Message messageId='currBase-res' content="I love pastry." style={{marginRight: "10px", marginTop: "10px", backgroundColor:"rgb(83, 255, 75)", width:"150px"}}></Message>
								<Message messageId='currBase' content={workflow.current.other.contentEN} style={{marginLeft: "10px", marginTop: "10px"}}></Message>
							</div>
						</Grid>
						<Grid item xs={6}>
							<h3 style={{  }}>Select Another Other Answer:</h3>
							<div style={{display: 'flex', flexFlow: 'row', overflowX: 'scroll', maxWidth: '100%', alignItems: 'flex-start'}}>
								{workflow.other_answer ? workflow.other_answer.map((message) => {
									return (
										<div style={{ marginRight: '20px', cursor: 'pointer'}} onClick={() => handleSubmit(message.messageId)} >
											<Message messageId={message.messageId} content={message.contentEN} style={{backgroundColor:"rgb(219, 118, 194)"}}/>
										</div>
									)
								}) : ''}
							</div>
						</Grid>
					</Grid>
				</Grid>

			</React.Fragment>
		)
	}

	useEffect(() => {
		const path = "sequences/" + sequence.sequenceId + "/workflow";
		axiosInstance.get(path)
			.then((res) => {
				setAppState({ loading: false, workflow: res.data});
				
				//console.log(res.data);
			});
	}, [setAppState]);

	if (!sequence || sequence.length === 0) return <p>Error loading the page</p>;
	return (
    <React.Fragment>
    	<DetailSequenceHeader sequence={sequence} />
		<h4 style={{ textAlign: 'center', marginTop: '50px' }}>It is NOT recommended to change those messages when the sequence is running.</h4>
		<PageData workflow={appState.workflow}/>
		<div item style={{ width: "500px", marginLeft: 'auto', marginRight: 'auto', marginTop: '20px' }}>
			<h2>Create new Option</h2>
			<Form>
				<Form.Select name="system" onChange={handleChangeMessage}>
					<option>Type</option>
					<option value="referral">Referral Message</option>
					<option value="right_answer">Right answer</option>
					<option value="other_answer">Other answer</option>
					<option value="base_text">Question Template</option>
				</Form.Select>
				<div id="reminder_base_test" style={displayAdvice}>
					<p style={{color: 'red'}}>You need to add &#123;&#123;1&#125;&#125; as a placeholder for the question</p>
				</div>
				<Form.Group style={{ marginTop: "20px"}}>
					<Form.Label>Content of Message:</Form.Label>
					<Form.Control
						as="textarea"
						rows="3"
						onChange={handleChangeMessage}
						name="contentEN"
					/>
				</Form.Group>
				<Button type="submit" style={{ marginTop: "10px" }} onClick={createNewMessage}>Create & Apply</Button>
			</Form>

		</div>
    </React.Fragment>
  );
};




export default DetailSequenceWorkflow;