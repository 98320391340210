import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import '../tables.css'
import { NavLink } from 'react-router-dom';
import CreateDiv from '../CreateDiv';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));



const TableCompanies = (props) => {
	const { companies, companyId } = props;
  const [showCreate, setShowCreate] = useState(false)

  const onClick = () => setShowCreate(true)

	if (!companies || companies.length === 0) {
    return (
      <React.Fragment>
        { showCreate ? <CreateDiv toCreate={"company"} stateChange={setShowCreate} companyId={companyId} /> : null }
        
        <Grid container>
          <Grid item xs={6}>
          </Grid>
          <Grid item xs={6}  style={{ textAlign: 'right'}}>
            <Button style={{ color: 'green'}} onClick={onClick}>Create</Button>
          </Grid>
        </Grid>
        <h2 style={{ textAlign: 'center' }}>No units created</h2>
      </React.Fragment>
    )
  }

  
  


	return (
    <React.Fragment>
      { showCreate ? <CreateDiv toCreate={"company"} stateChange={setShowCreate} companyId={companyId} /> : null }
      
      <Grid container>
        <Grid item xs={6}>
        </Grid>
        <Grid item xs={6}  style={{ textAlign: 'right'}}>
          <Button style={{ color: 'green'}} onClick={onClick}>Create</Button>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell width="40%" className="table_head_title">Company Name</StyledTableCell>
              <StyledTableCell width="30%" align="center" className="table_head_title">Number Registered Employees</StyledTableCell>
              <StyledTableCell width="30%" align="right" className="table_head_title">End Date of Sequence</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companies.map((company) => {
              if (!company.is_archived) {
                return (
                  <StyledTableRow key={company.companyId}>
                    <StyledTableCell component="th" scope="row">
                    <NavLink to={"/units/" + company.companyId } className="table_cell_link">
                      {company.name}
                    </NavLink>
                    </StyledTableCell>
                    <StyledTableCell align="center">{company.num_employee}</StyledTableCell>
                    <StyledTableCell align="right">
                      <b>
                        {company.lastSeq.active ? 
                          company.lastSeq.end_of_sequence : "Not In Sequence"
                        }
                      </b>
                      
                      </StyledTableCell>
                  </StyledTableRow>
                )
              }
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};
export default TableCompanies;
