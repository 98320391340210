import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { pink } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ButtonBS from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import FormGroup from '@mui/material/FormGroup';

import axiosInstance from '../../axios';

import AddMessageOption from './addMessageOption'
import './sequence.css'

import Message from '../chat/message'


import DetailSequenceHeader from './detailSequenceHeader'
import TableMessagesSequence from './tableMessagesSequence'

import { useParams, useSearchParams } from "react-router-dom";

import MainLoadingComponent from '../MainLoading';
import { NavLink } from 'react-router-dom';



const RSchemeForm = (props) => {
  const { options_messages, responseMess, number_of_options, optionsSelected, message, sequenceId } = props;


  const [formData, updateFormData] = useState({
    triggers: optionsSelected,
    followUpQuestion: "nofollowup",
    custom: '',
    showCustom: false,
  })


  var curResponseSelected = "nofollowup";

  if (responseMess) {
    curResponseSelected = responseMess.messageOption.messageId;
  }

  // updateFormData({
  //   ...formData,
  //   triggers: optionsSelected,
  //   followUpQuestion: curResponseSelected,
  // })


  const handleChange = (e) => {
    var newtriggers = formData.triggers;
    const index = newtriggers.indexOf(e.target.name);

    if (index !== -1) {
      const x = newtriggers.splice(index, 1);
    } else {
      newtriggers.push(e.target.name);
    }

    updateFormData({
      ...formData,
      triggers: newtriggers,
    })
	};


  var optionsToShow = true;

  if (!message) return ""
  


  const createNewMessage = (e) => {
    e.preventDefault();

    if (formData.triggers.length !== 0) {
      if (formData.followUpQuestion === 'custom') {
        if (formData.custom !== '') {
          console.log('sequences/' + sequenceId + "/replying_scheme/" + message.id);
          axiosInstance.post('sequences/' + sequenceId + "/replying_scheme/" + message.id, {
              triggeringContents:  formData.triggers,
              responseMess_option_content: formData.custom,
            }).then((res) => {
              console.log(res.data);
              window.location.reload();
          });
        } 
      } else {
        console.log(message.id);
        console.log(formData);
         
            axiosInstance.post('sequences/' + sequenceId + "/replying_scheme/" + message.id, {
                triggeringContents:  formData.triggers,
                responseMess_option_id: formData.followUpQuestion,
              }).then((res) => {
                console.log(res.data);
                window.location.reload();
            });
        

        

      }
    }

  };

  const CheckBoxContent = (props) => {
    const { content, number } = props;


    if (content.includes(number.toString())){ // Modify code to use toString() method
      
      return (
        <FormControlLabel
          control={
            <Checkbox onChange={handleChange} name={number} defaultChecked />
          }
          label={number}
        />
      )
    }else {
      return (
        <FormControlLabel
          control={
            <Checkbox onChange={handleChange} name={number} />
          }
          label={number}
        />
      )
    }
  }

    


  const handleChangeMessage = (e) => {
    if (e.target.name === "followUpQuestion") {
      var showCustom = false;
      if (e.target.value === "custom") {
        showCustom = true;
      } 

      updateFormData({
        ...formData,
        [e.target.name]: e.target.value,
        showCustom: showCustom,
      })
      
    } else {
      updateFormData({
        ...formData,
        [e.target.name]: e.target.value,
      })
    }
    

  };

  var checkboxes = [];

  for (let i = 1; i <= number_of_options; i++) {
    checkboxes.push(
      <CheckBoxContent content={optionsSelected} number={i} />
    )
  }


  
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid item xs={12}>
            <h3>Follow Up Question</h3>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                {
                  message.freeTextQuestion ? '' : (
                    <FormControl>
                      <FormLabel component="legend">Answers Triggering Question</FormLabel>
                      <FormGroup row>
                        {checkboxes}
          
                      </FormGroup>
                    </FormControl>
                  )
                }
                
              </Grid>
              <Grid item xs={12}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={curResponseSelected}
                  name="followUpQuestion"
                  onChange={handleChangeMessage}
                >
                  <Grid container columnSpacing={10} style={{ overflowX: 'auto', height: '700px', overflowY: 'auto' }}>
                    <Grid item xs={6}>
                      <FormControlLabel value="nofollowup" control={<Radio />} label="Normal Question / No Follow Up" />
                    </Grid>
                    {
                      message.freeTextQuestion ? '' : (
                        <React.Fragment>
                          <Grid item xs={6}>
                            <FormControlLabel value="custom" control={<Radio />} label="Other" />
                          </Grid>
                            {
                              options_messages.map((option) => {
                                return (
                                  <Grid key={option.messageId} item xs={6}>
                                    <FormControlLabel value={option.messageId} key={option.messageId} control={<Radio />} label={<div className='message'>{option.contentEN}</div>} />
                                  </Grid>
                                )
                              })
                            }
                            
                            
                        </React.Fragment>
                      )
                    }
                  </Grid>
                  </RadioGroup>
              </Grid>
            </Grid>
        </Grid>
        
        {
          formData.showCustom ? 
          (
            <Grid item xs={12}>
              <Form.Group controlId="exampleForm.ControlTextarea1" style={{ marginTop: "20px", marginRight: '40px'}}>
                  <Form.Label>'Other' Follow Up Question:</Form.Label>
                  <Form.Control
                      as="textarea"
                      rows="3"
                      onChange={handleChangeMessage}
                      name="custom"
                      value={formData.custom}
                  />
              </Form.Group>
            </Grid>
          ) : ''
        }
        {/* <CustomQuestion /> */}

        <Grid item xs={12}>
          {
            message.freeTextQuestion ? 
            '' : 
            <Button type="submit" style={{ marginTop: "10px" }} onClick={createNewMessage}>Update Response Scheme</Button>
          }
          
        </Grid>
      </Grid>
      <Grid item xs={12}>
         
      </Grid>
    </Grid>
  )

};

const Response_options_form = (props) => {
  const { options, message, messageId, template_options, sequenceId } = props;

  // console.log(options)

  const [formOptions, setFormOptions] = useState(options)

  const [formResponses, setFormResponses] = useState({
    number_of_options: message.number_of_options,
    freeTextQuestion: message.freeTextQuestion,
    custom_template: message.custom_template,
  })

  const updateTextField = (e) => {
    setFormOptions({
      ...formOptions,
      [e.target.name]: e.target.value,
    })
  }

  const updateAnswerOptions = () => {
    const dataToSend = {
      freeTextQuestion: formResponses.freeTextQuestion,
      options: formOptions,
      number_of_options: formResponses.number_of_options,
    }
    console.log(dataToSend)
    axiosInstance.post('sequences/' + sequenceId + '/answer_options/' + messageId, dataToSend)
    .then((res) => {
      window.location.reload();
      
    });

  }

  if (!formResponses || Object.keys(formResponses).length === 0)  {
    return ''
  }



  const TemplateField = () => {
    // console.log(template_options)
    const handleSubmit = function(template_id) {
      const dataToSend = {
        custom_template: template_id,
      }

      console.log(dataToSend)
        axiosInstance.post('sequences/' + sequenceId + '/answer_options/' + messageId, dataToSend)
        .then((res) => {
          window.location.reload();
          
        });
      };

    return (
      <Grid container spacing={4} style={{marginTop: '-20px'}}>
        <Grid item xs={12}>
          <h3 style={{ }}>Question Template for this message:</h3>
          {
            formResponses.custom_template ? (
              <Message messageId='custom' content={formResponses.custom_template.contentEN} style={{marginLeft: "10px", marginTop: "10px"}}></Message>
            ) : "This question uses the default template."
          }
        </Grid>
        <Grid item xs={12}>
          <h3 style={{  }}>Select Another Template:</h3>
          <p onClick={() => handleSubmit("")} style={{ cursor: 'pointer', color: 'grey'}}>Click here to remove specific template for this question</p>
          <div style={{display: 'flex', flexFlow: 'row', overflowX: 'scroll', maxWidth: '100%', alignItems: 'flex-start'}}>
            {template_options.map((message) => {
              return (
                <div key={message.messageId} style={{ marginRight: '20px', cursor: 'pointer'}} onClick={() => handleSubmit(message.messageId)} >
                  <Message messageId={message.messageId} content={message.contentEN} style={{backgroundColor:"rgb(219, 118, 194)"}}/>
                </div>
              )
            })}
          </div>
        </Grid>
      </Grid>
  )
  };

  const handleChangeCheckBox = (e) => {
    setFormResponses({
      ...formResponses,
      freeTextQuestion: e.target.checked
    })
   
	};

  var option_grid = [];

    for (let i = 1; i <= formResponses.number_of_options; i++) {
      option_grid.push(
        <Grid key={i} container spacing={2} style={{ marginLeft: '20px' }}>
          <Grid item xs={2} style={{ textAlign: 'right'}}>
              <p style={{ fontSize: '1.2rem', fontWeight: 'bold'}}>{i}:</p>
          </Grid>
          <Grid item xs={10}>
              <TextField variant="standard" value={formOptions[i]} name={i} onChange={updateTextField} />
          </Grid>
        </Grid>
      )
    }
    

  return (
    <React.Fragment>
        <Form>
          <h3>Message options</h3>
          <FormControlLabel
            control={
              <Checkbox onChange={handleChangeCheckBox} name="freeTextQuestion" checked={formResponses.freeTextQuestion} />
            }
            label="Free text answer."
          />
          {
            formResponses.freeTextQuestion ? '' : 
            (
              <React.Fragment>
                <br/><br/>
                <TextField
                  label="Number of Options"
                  value={formResponses.number_of_options}
                  onChange={(e) =>
                    setFormResponses({
                      ...formResponses,
                      number_of_options: e.target.value,
                    })
                  }
                /><br/><br/>
                {option_grid}
              </React.Fragment>
            )
            }
          <br/>
          <Button style={{ marginTop: "10px" }} onClick={updateAnswerOptions}>Update Answer Options</Button>
                  
            <TemplateField />
            
        </Form>
    </React.Fragment>
  )
}

const Schemes = (props) => {
  const { schemes, options_messages, template_options, sequenceId } = props;

  if (!schemes) return ""

  console.log(schemes);

  return (
    <React.Fragment>
      {
        schemes.map((scheme) => {
          return (
            <Grid item xs={12} key={scheme.message.id}>
              <h2 style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px', color: 'grey' }}>{ scheme.message.messageOption.contentEN }</h2>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Response_options_form options={scheme.responseOptions} message={scheme.message} template_options={template_options} messageId={scheme.message.id}  sequenceId={sequenceId}  />
                </Grid>
                <Grid item xs={6}>
                    <RSchemeForm options_messages={options_messages} number_of_options={scheme.message.number_of_options} responseMess={scheme.responseMess} optionsSelected={scheme.content} sequenceId={sequenceId} message={scheme.message} />
                </Grid>
              </Grid>
            </Grid>
          )
        })
      }
    </React.Fragment>
  )

}



const PageContent = (props) => {
  const { sequenceId, rsData, setSearchParams } = props;
  // const initialFormData = Object.freeze({
	// 	name: '',
	// 	speedyForm: '',
	// });


  const handleChange = (e) => {
    if (e.target.value === "Message") {
      setSearchParams(params => {
        params.delete("cur_message");
        return params;
      });
      // setSearchParams
      // var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?response_scheme=1';
      // window.history.pushState({path:newurl},'',newurl);
      // updateRsData({ schemes: null, options_messages: null,  messageId: null });
    } else {
      setSearchParams(params => {
        params.set("cur_message", e.target.value);
        return params;
      });

    //   axiosInstance.get('sequences/' + sequenceId + '/replying_scheme/' + e.target.value)
    //     .then((res) => {
    //       const schemes = res.data.schemes;
    //       const options_messages = res.data.options_messages;
    //       updateRsData({ schemes: schemes, options_messages: options_messages,  messageId: e.target.value });
          
    //     });
    }
	};

  
  if (!rsData.messages || rsData.messages.length === 0) return <p>Error loading the page</p>;

  
  console.log(rsData);

  return (
    <React.Fragment>
      <Grid container spacing={2} justify="flex-end" alignItems="center">
          <Grid item xs={12}>
              <div style={{ width: '500px', marginLeft: 'auto', marginRight: 'auto'}}>
                <Form.Select aria-label="Default select example" name="message" onChange={handleChange} value={rsData.messageId}>
                    <option>Message</option>
                    {
                    rsData.messages.map((message) => {
                      return(
                        <option key={message.id} value={message.id}>{message.messageOption.contentEN}</option>
                      )
                    })
                    }
                </Form.Select>
              </div>
          </Grid>
          <Schemes schemes={rsData.schemes} template_options={rsData.template_options} options_messages={rsData.options_messages} sequenceId={sequenceId} />
      </Grid>
    </React.Fragment>
  )

};



const ReplyingScheme = (props) => {
	const { sequence } = props;
  const LoadingMess = MainLoadingComponent(PageContent);

  const [searchParams, setSearchParams] = useSearchParams();

  const cur_mess = searchParams.get("cur_message");

  const [rsData, updateRsData] = useState({
    schemes: null,
    options_messages: [],
    messageId: null,
    loading: true,
		messages: null,
    template_options: [],
  });

  
  useEffect(() => {
		axiosInstance.get('sequences/' + sequence.sequenceId + '/messages')
			.then((res) => {
				const messages = res.data;

        if (cur_mess) {
          axiosInstance.get('sequences/' + sequence.sequenceId + '/replying_scheme/' + cur_mess)
            .then((res2) => {
              const schemes = res2.data.schemes;
              const options_messages = res2.data.options_messages;
              const template_options = res2.data.template_options;
              updateRsData({ loading: false, messages: messages, schemes: schemes, options_messages: options_messages, template_options: template_options, messageId: cur_mess });
            });
        } else {
          updateRsData({ ...rsData, loading: false, messages: messages });
        }
			});
	}, [updateRsData]);


  


  
	if (!sequence || sequence.length === 0) return <p>Error loading the page</p>;


	return (
    <React.Fragment>
      <DetailSequenceHeader sequence={sequence} />
          <h2 style={{ marginBottom: "20px"}}>Replying Schemes</h2>
        <div style={{ marginTop: "20px" }}>
          <LoadingMess isLoading={rsData.loading} sequenceId={sequence.sequenceId} rsData={rsData} setSearchParams={setSearchParams}/>
        </div>

        
    </React.Fragment>
  );
};




export default ReplyingScheme;