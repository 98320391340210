import React, { useState } from 'react';
import axiosInstance from '../axios';

//MaterialUI
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';



export default function ChangePass() {
	const initialFormData = Object.freeze({
		oldPassword: '',
		newPassword: '',
		confPassword: '',
		errorMessage: '',
	});

	const [formData, updateFormData] = useState(initialFormData);

	const handleChange = (e) => {
		updateFormData({
			...formData,
			[e.target.name]: e.target.value.trim(),
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(formData);

		if (formData.newPassword !== formData.confPassword) {
			console.log('yiiiii');
			updateFormData({...formData, errorMessage: "The passwords do not match"})
			return
		} 

		if (formData.newPassword.length < 8) {
			console.log('yidasdasdadiiii');
			updateFormData({...formData, errorMessage: "New password needs to be at least 8 character long"})
			return
		}

		axiosInstance
			.post(`change-password/`, {
				oldPassword: formData.oldPassword,
				newPassword: formData.newPassword,
			})
			.then((res) => {
				const success = res.data;
				console.log(success);
				if (res.status === 200) {
					window.location.href = "/";
				} else {
					if (success.success == "wrong_pass") {
						updateFormData({...formData, errorMessage: "Wrong Password"})
					}
				}
				
               
			});
	};


	return (
		<Container component="main" className='main_app' maxWidth="xs">
			<CssBaseline />
			<div >
				<Typography component="h1" variant="h5">
					Change Password
				</Typography>
				<form noValidate>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="oldPassword"
						label="Old Password"
						type="password"
						id="oldPassword"
						autoComplete="current-password"
						onChange={handleChange}
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="newPassword"
						label="New Password"
						type="password"
						id="newPassword"
						autoComplete="new-password"
						onChange={handleChange}
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="confPassword"
						label="Confirm New Password"
						type="password"
						id="confPassword"
						autoComplete="new-password"
						onChange={handleChange}
					/>
					<p style={{ color: 'red' }}>{formData.errorMessage}</p>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						onClick={handleSubmit}
					>
						Change Password
					</Button>
				</form>
			</div>
		</Container>
	);
}
