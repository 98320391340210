import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import axiosInstance from '../../axios';

import '../tables.css'
import './company.css'

import TableCompanies from './tableCompanies';
import DetailCompanyHeader from './detailCompanyHeader'
import TableEmployees from './tableEmployees'
import { Table } from '@mui/material';

import SequenceListsCompany from '../sequence/sequenceListsCompany.js'

import MainLoadingComponent from '../MainLoading';
import OneOffMessage from './oneOffMessage';


const DetailCompanyGroup = (props) => {
	const { company } = props;
	const initialFormData = Object.freeze({
		name: '',
		description: '',
    linkToLogo: '',
	});

	const [formData, updateFormData] = useState(initialFormData);

	useEffect(() => {
		axiosInstance.get('companies/' + company.companyId).then((res) => {
			updateFormData({
				...formData,
				['name']: res.data.name,
				['description']: res.data.description ? res.data.description : '',
        		['linkToLogo']: res.data.linkToLogo ? res.data.linkToLogo : '',
			});
			console.log(res.data);
		});
	}, [updateFormData]);

	const handleChange = (e) => {
		updateFormData({
			...formData,
			// Trimming any whitespace
			[e.target.name]: e.target.value.trim(),
		});
	};
	const archive = (e) => {
		axiosInstance.patch('companies/' + company.companyId, {
		  is_archived: true,
			}).then((res) => {
		  console.log(res.data);
		});
	
		window.location.reload();
	  }


	const updateDetails = (e) => {
		e.preventDefault();
		console.log(formData);

		axiosInstance.patch('companies/' + company.companyId, {
			name: formData.name,
      		description: formData.description,
			linkToLogo: formData.linkToLogo,
		}).then((res) => {
      console.log(res.data);
    });

		window.location.reload();
	};


	if (!company || company.length === 0) return <p>Error loading the page</p>;
	return (
    <React.Fragment>
      <div style={{ borderBottom: '1px solid black'}}>
        <h2 style={{ marginBottom: "20px" }}>Edit Company Details</h2>
        <form noValidate>
		<Grid container spacing={4}>
			<Grid item xs={4}>
              <Grid container spacing={4} style={{marginTop: '-20px'}}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="name"
                    label="Company Name"
                    name="name"
                    autoComplete="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="linkToLogo"
                    label="URL of logo"
                    name="linkToLogo"
                    autoComplete="linkToLogo"
                    value={formData.linkToLogo}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
						</Grid>
						<Grid item xs={8}>
							<TextField
								variant="outlined"
								required
                fullWidth
								id="description"
								label="Company description | Various Information:"
								name="description"
                style={{ marginBottom: '40px' }}
								autoComplete="description"
								value={formData.description}
								onChange={handleChange}
								multiline
								rows={6}
							/>
						</Grid>
					</Grid>
					<Button
						type="submit"
						style={{ float: 'right' }}
            			className="submitButton"
						variant="contained"
						color="primary"
						onClick={updateDetails}
					>
						Update Company Details
					</Button>
				</form>
				<Button
					type="submit"
					style={{ float: 'left', marginTop: "30px" }}
					className="submitButton"
					variant="contained"
					color="primary"
					onClick={archive}
					>
					Archive Company
				</Button>

        <div style={{ clear: 'both' }}></div>
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
         <TableCompanies companies={company.units} companyId={company.companyId} />
        </Grid>
      </Grid>
      
        
    </React.Fragment>
  );
};




export default DetailCompanyGroup;